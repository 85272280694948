import { CombinedSets } from "./CombinedSets";

export interface CombinedLine {
	company: string;
	businessUnit: string;
	signedLinePercentageString: string;
}
export const CombinedSetUtils = {
	analyse : ( data : CombinedSets.RootObject[] ) : CombinedLine[] => {
		const retval : CombinedLine[] = [];
		data.forEach( itm => {
			if( itm.type != "RWSignedLineSet") {
				return;
			}
			itm.contents.forEach( cont => {
				cont.impressions.forEach( imp => {
					if( imp.signedLinePercentageString ) {
						const signedLine: CombinedLine = { 
							company: cont.businessUnit,  
							businessUnit: imp.stamp?.businessUnit || "",
							signedLinePercentageString: imp.signedLinePercentageString
						};
						retval.push( signedLine );
					}
				});
			});
		})
		return retval;
	}
}