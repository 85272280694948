import { Session } from "../../Session"
import dotenv from 'dotenv';
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { NotificationManager } from 'react-notifications'
import { RBSettings, RulebookAPI } from "../../helpers/RulebookAPI";
import { Query, whitespace, WhitespaceAPI } from "../../helpers/whitespace";
import { TabbedViewer } from "../../components/TabbedViewer";
import { RBFiller, RBRisk } from "./RBRisk";
import { SummaryFilter } from "../../components/SummaryFilter";
import { payloads } from "../../helpers/payloads";
import { riskFilter } from "../../helpers/riskFilter";
import { RiskGrid } from "../../components/RiskGrid";
import { DDDataPoint, DefinedData } from "../../helpers/DefinedData";
import { utils } from "../../helpers/utils";
import { JMRC } from "../../rebrowse/jmrc";
import dayjs from "dayjs";

dotenv.config();

export const RulebookCaller = () => {
    const [risks, setRisks] = useState<any[]>([])
    const [risk, setRisk] = useState<JMRC.Root | null>(null);
    const [responses, setResponses] = useState<any[]>([])

    useEffect(() => {
        RulebookAPI.initSettings( process.env.REACT_APP_RULEBOOK_ROOT, process.env.REACT_APP_RULEBOOK_USER, process.env.REACT_APP_RULEBOOK_PASS );
        RulebookAPI.connect().then( response => {
            addURLResponse( "rulebookAPI.connect()", response );
            if( response.access_token ) {
                RBSettings.token = response.access_token;
            }
        });
    }, [])

    const getRBToken = () => {
        NotificationManager.success( RBSettings.token ? "Token starts " + RBSettings.token.substring(0,20) : "Token is blank" );
    }

    const doSummary = ( grail ) => {
        grail = grail.trim();
        const payload = Session.userDetails.role == "broker" ? payloads.summary_Draft( grail ) : payloads.summary_QuotationRequest( grail );

        setRisks( [] );
        whitespace(Query.POST, '/api/summary', payload ).then(response => {
            console.log( "POST /api/summary complete");
            setRisks( riskFilter.groupRisksSummary( response.data ));
        })
    }

    const pickRisk = ( risk ) => {
        getDefinedData( risk );
    }

    const getDefinedData = ( risk ) => {
        console.log( "getDefinedData", risk );
        const url = `/api/v22.04/data/${risk.id}`;
        WhitespaceAPI.get( url ).then( response => {
            const array = [...responses];
            array.push( { url : url, response: JSON.stringify( response, null, 4 ) } );
            const rbrisk = makeRuleBookPayload( response.data.definedData );
            array.push( { url : "RBRisk", response: JSON.stringify( rbrisk, null, 4 ) } );
            setResponses( array );

            /* fails for CORS
            const product = process.env.REACT_APP_RULEBOOK_PROD;
            url = `/api/REST/${product}/saverisk`;
            RulebookAPI.post( url, rbrisk ).then( response => {
                array.push( { url : url, response: JSON.stringify( response, null, 4 ) } );
                
            });
            */
        });
    }

    const makeRuleBookPayload = ( ddpoints ) : RBRisk => {
        const rbfiller = new RBFiller( ddpoints );
        return rbfiller.make();
    }

    const clearResponses = () => {
        setResponses( [] );
    }

    const addURLResponse = ( url, response ) => {
        const array = [...responses,  { url : url, response: JSON.stringify( response, null, 4 ) } ];
        console.log( `addURLResponse length now ${array.length}`);
        setResponses( array );
    }
    const doSomething = ( url ) => {
        WhitespaceAPI.get( url ).then(response => {
            addURLResponse( url, response );
        });
    }

    const mockInput = () => {
        const array = [...responses];
        const rbRisk = RBRisk.makeExample();
        array.push( { url : "mockInput", response: JSON.stringify( rbRisk, null, 4 ) } );
        const product = process.env.REACT_APP_RULEBOOK_PROD;
        const url = `/api/REST/${product}/saverisk`;
        RulebookAPI.post( url, rbRisk ).then( response => {
            array.push( { url : url, response: JSON.stringify( response, null, 4 ) } );
            setResponses( array );
        });
    }
    

    // POST /api/risks/$QR/changeDraftNowQuoteInPreparation

    const setPremium = () => {
        if( risk == null ) {
            return;
        }
        if( Session.userDetails.role == "broker" || risk.control.status == "QuotationInPreparation") {
            callEditPremium();
            return;
        }
        NotificationManager.error( `Not yet handling ${Session.userDetails.role} status ${risk.control.status}`);
    }

    const callEditPremium = () => {
        if( risk == null ) {
            return;
        }
        let url = `/api/risks/${risk._id}/lineitem/Premium`;
        WhitespaceAPI.get( url ).then( response => {
            const array = [...responses];
            array.push( { url : url, response: JSON.stringify( response, null, 4 ) } );

            const payload = response.data[ 0 ];
            payload.op = "edit";
            payload._rev = risk._rev;
            const ph = payload.placeholders.filter( ( ph ) => { return ph.tags.includes( "Premium_Amount")})[0];

            let randomamt = dayjs(new Date()).format("Hmmss");
            randomamt = randomamt.substring( 0, randomamt.length - 3) + "," + randomamt.substring( 3 );

            ph.value = "USD " + randomamt;
            ph.originalValue = "USD " + randomamt;
            array.push( { url : "POST Payload", response: JSON.stringify( payload, null, 4 ) } );
            url = `/api/risks/${risk._id}/editLineItem`;
            WhitespaceAPI.post( url, payload ).then( response2 => {
                array.push( { url : url, response: JSON.stringify( response2.data, null, 4 ) } );
                setResponses( array );
            })
        });
    }

    return (
        <>
        <div className="WarningBanner">CORS stops this demo calling RuleBook<br/>Use NodeScripts/RulebookCaller instead</div>
        <SummaryFilter onSearch={doSummary}></SummaryFilter>
        <RiskGrid input={risks} pickFn={pickRisk} singleLine={true} />
        { risk != null &&
            <div>
                UMR:{ risk.control.umr } Insured:{ risk.control.insuredName } ID:{risk._id}
            </div>
        }

        <div>
        <span className="MyLink" onClick={() => {doSomething("/api/user/myDetails")}}>myDetails</span> 
        &nbsp;:&nbsp;<span className="MyLink" onClick={() => {doSomething("/api/user/colleagues")}}>colleagues</span>
        &nbsp;:&nbsp;<span className="MyLink" onClick={mockInput}>mock input</span>
        &nbsp;:&nbsp;<span className="MyLink" onClick={clearResponses}>clear</span>
        &nbsp;:&nbsp;<span className="MyLink" onClick={setPremium}>set premium</span>
        &nbsp;:&nbsp;<span className="MyLink" onClick={getRBToken}>rulebook token</span>
        </div>
        <div>
            <TabbedViewer inputs={responses}></TabbedViewer>
        </div>
        </>
    )
}