import dayjs from 'dayjs'
import { CLIENT } from '../../constants/client'
import { mrcItemToEOC, JSONSR } from '../../helpers/mrc'
import { whitespace, Query, WhitespaceAPI } from '../../helpers/whitespace'
import { CorporateLogoBase64 } from '../../components/CorporateLogo'

export const buildEOC = async (risk, variables: Record<string,string>): Promise<any> => {
    const signedLineSets: Array<unknown> = []
    risk.signedLineSets.map(signedLineSet => signedLineSet.contents.map(content => signedLineSets.push([content])))
    let EOC: Record<string, any> = CLIENT.eocTemplate
    // EOC.header[0].columns[0].text = dayjs().format('D MMMM YYYY')
    EOC.content[0].text = variables.address
    EOC = JSONSR(EOC, '{{DATE}}', variables.documentDate)
    EOC = JSONSR(EOC, '{{PPT}}', variables.PPT)
    const LCWSindex = EOC.content.indexOf('LCWS')
    if( LCWSindex >= 0 ) {
        if(variables.LCWS) {
            EOC.content.splice(LCWSindex, 1)
            EOC = JSONSR(EOC, '{{LCWS}}', variables.LCWS)
        } else {
            EOC.content.splice(LCWSindex, 3)
        }
    }

    EOC = (variables.UMR) ? JSONSR(EOC, '{{UMR}}', variables.UMR) : mrcItemToEOC(risk, EOC, 'UMR', '{{UMR}}')
    EOC = (variables.insured) ? JSONSR(EOC, '{{INSURED}}', variables.insured) : mrcItemToEOC(risk, EOC, 'Insured', '{{INSURED}}')

    const insured_block_index = EOC.content.indexOf('INSURED_BLOCK');
    if( insured_block_index >= 0 ) {
        const insured_block = formatInsuredBlock( variables );
        EOC.content.splice( insured_block_index, 1, insured_block);
    }

    EOC = (variables.period) ? JSONSR(EOC, '{{PERIOD}}', variables.period) : mrcItemToEOC(risk, EOC, 'Period', '{{PERIOD}}')
    EOC = (variables.orderHereon) ? JSONSR(EOC, '{{ORDER_HEREON}}', variables.orderHereon) : mrcItemToEOC(risk, EOC, 'OrderHereon', '{{ORDER_HEREON}}')
    EOC = JSONSR(EOC, '{{TYPE}}', variables.contractType)
    const signedLinesIndex = EOC.content.indexOf('SIGNED_LINES')
    EOC.content.splice(signedLinesIndex, 1)
    const formattedSignedLines = await formatSignedLines(risk, signedLineSets, CLIENT.eocStyles.insuredTableBorder, variables )
    EOC.content = EOC.content.slice(0,signedLinesIndex).concat(formattedSignedLines,EOC.content.slice(signedLinesIndex))
    
    EOC.images.logo = 'data:image/png;base64,' + CorporateLogoBase64 // CLIENT.logo
    return EOC
}

const formatInsuredBlock = ( variables: Record<string,string> ) => {
    const output: Record<string, any> = [];
    const body: Record<string, any> = [];

    body.push(
        [ 
            { 'text' : variables.originalInsured ? 'Reinsured:' : 'Insured', 'style' : 'second_title' }, 
            { 'text' : variables.insured, 'style' : 'third_title' }
        ] );
    if( variables.originalInsured ) {
        body.push(
            [ 
                { 'text' : 'Original Insured:', 'style' : 'second_title' }, 
                { 'text' : variables.originalInsured, 'style' : 'third_title' }
            ]);
    }

    output.push( {
        'layout': {
          'defaultBorder': false
        },
        'style':'second_title',
        'table':{'widths':['25%','*'],'body': body}
      });
    return output;
}

const getDocumentSectionsIndexes = async riskID => {
  const document = await whitespace(Query.GET, `/api/documents/${riskID}::MS`, null, { silent: true } );
  if( document && document.data?.sections && document.data?.sections.length > 0 ) {
    return document.data.sections;
  }
  return false;
}

const formatSignedLines = async (risk, signedLineSets, insuredTableBorder, variables ) => {
    const documentSectionsIndexes = await getDocumentSectionsIndexes(risk.control.originalDocID.split('::')[0]);
    return formatSignedLinesInner( risk, signedLineSets, insuredTableBorder, variables,documentSectionsIndexes );
}

export const formatSignedLinesInner = ( risk, signedLineSets, insuredTableBorder, variables,documentSectionsIndexes ) => {
    // const sectionsSet = new Set(signedLineSets.map(lineSet => lineSet[0].sectionIdentifiers[0]))
    // const sections = Array.from(sectionsSet)
    const sections = documentSectionsIndexes ? documentSectionsIndexes.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0)).map(section => section.multiSectionId) : [undefined]
    // console.log( `formatSignedLines ${risk.control.originalDocID}`);
    // console.log( documentSectionsIndexes );
    // console.log( sections );
    // console.log( signedLineSets );


    // putting the lead underwriter first is quite complex
    // see LeadUnderwriterInvestigator.swift for the amount of logic and data included
    signedLineSets.sort((a, b) => {
      const aLead = a[0].businessUnit == variables.leadUnderwriter
      const bLead = b[0].businessUnit == variables.leadUnderwriter
      const aDate = dayjs(a[0].stampedAt).unix();
      const bDate = dayjs(b[0].stampedAt).unix();
      const aPerc = parseFloat( a[0].impressions[0].signedLinePercentageString);
      const bPerc = parseFloat( b[0].impressions[0].signedLinePercentageString);

      if( aLead ) { return -1; }  
      if( bLead ) { return 1; }

      if( aPerc < bPerc ) { return 1; }
      if( aPerc > bPerc ) { return -1; }
      
      if( aDate < bDate ) { return -1; } // if (dayjs(a[0].stampedAt).unix() < dayjs(b[0].stampedAt).unix()) {
      if( aDate > bDate ) { return 1; }// if (dayjs(a[0].stampedAt).unix() > dayjs(b[0].stampedAt).unix()) {
      return 0
    })
    console.log( `formatSignedLines found ${signedLineSets.length} signed lines, ${sections.length} sections`);
    const output: Record<string, any> = []
    const pattern = new RegExp("added[^)]*", 'i' );
    sections.map(section => {
      const sectionedSignedLineSets = signedLineSets.filter(lineSet => lineSet[0].sectionIdentifiers[0] === section)
      const percentageReducer = (accumulator, lineSet) => {
        let impressionAccumulator = 0
        lineSet[0].impressions.map(impression => impressionAccumulator += parseFloat(impression.signedLinePercentageString))
        return accumulator + impressionAccumulator
      }
      const totalForSection = sectionedSignedLineSets.reduce(percentageReducer, 0).toFixed(4)
      const sectionedSignedLineSetsOutput: Record<string,any> = []
      
      sectionedSignedLineSets.map(lineSet => {
          lineSet[0].impressions.map(signedLine => {

            console.log( signedLine );

              // could not find a way to include the brackets in the regex ?!            
            const businessUnit = signedLine.stamp.businessUnit.replace( pattern, '').replaceAll( '()', '' );
            //const businessUnit = signedLine.stamp.businessUnit.replaceAll( '(added by McGill and Partners)', '');
            sectionedSignedLineSetsOutput.push([
                {'text': `${signedLine.signedLinePercentageString}%`,'style':'right_aligned', 'colSpan': 1},
                {'text': `${businessUnit}`, 'colSpan': 1}
            ])
          })
      });
      sectionedSignedLineSetsOutput.push([
        {'text': `${totalForSection % 1 != 0 ? totalForSection : parseInt(totalForSection)}%`,'style':'bold_right_aligned', 'colSpan': 1},
        {'text': 'Total', 'style':'bold', 'colSpan': 1}
      ]);

      output.push({
        'style': 'sub_title',
        'text': `\n${(typeof(section) === 'undefined') ? '' : `${section} insured with:\n\n`}`
      })
      output.push({
        'layout': {
          'defaultBorder': insuredTableBorder
        },
        'style':'table_down',
        'table':{'widths':['15%','*'],'body': sectionedSignedLineSetsOutput}
      })
    });
    console.log(`formatSignedLines output follows.`);
    console.log(output);
    return output
}
