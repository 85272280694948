import React, { useState, useEffect } from 'react'
import './Analytics.css'
import { infographics } from './Infographics/registrar'
import spikes from '../../assets/images/spikes.png'

const backgrounds = {
  bar: spikes,
  pie: spikes,
  line: spikes
}

export const Analytics = () => {
    const [infographic, setInfographic] = useState(infographics()[0])

    const infographicMenu = () => {
      return (
        <>
          {infographics().map(infographic => {
            if(infographic.title) return (
              <div 
                onClick={() => setInfographic(infographic)}
                className="Infographic-menu-option" 
                style={{backgroundImage: `url(${backgrounds[infographic.type]}`}}
              >
                <div className="Infographic-menu-option-text-wrapper">
                  <div className="Infographic-menu-option-text">
                    {infographic.title}
                  </div>
                </div>
              </div>
            )
          })}
        </>
      )
    }

    if (!infographic.title) {
      return infographicMenu()
    } else {
      return (
          <>
            <button 
              className="btn btn-primary back-btn" 
              onClick={() => setInfographic(infographics()[0])}>
                Menu</button>
            <infographic.component />
          </>
      )
    }
}