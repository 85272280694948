import './MRCViewer.css'
import {LineItem, OrganiseLineItems, OrganisedCategory} from './line-item'
import {JMRC} from './jmrc'
import {LineItemView} from './line-item-view'

interface Section {
    name: string;
    sect: JMRC.LineItem;
    items: LineItem[];
}

function MRCSection(props: { data: Section }) {

    const lineItems = props.data.items.map((item,idx) => {
        return (
            <div key={idx} className="mrc-line-item row">
                <div className="col-3 line-item-name">
                    {item.name}
                </div>
                <div className="col-9">
                    <LineItemView items={item.elements} child={false} listStyle="none" />
                </div>
            </div>
        )
    })

    return (
        <div>
            <div className="mrc-section-heading card-header">
                <h4>{props.data.name}</h4>
                <i className="fa fa-chevron-up"></i>
            </div>
            {lineItems}
        </div>
    )
}

export const MRCViewer = ( { lineItems }) => {

    const sections: Section[] = []

    OrganiseLineItems( lineItems, (type, line, parent) => {
        if(type === OrganisedCategory.Section) {
            const sect = {
                name: line.originalHeading,
                sect: line,
                items: [],
            }
            sections.push(sect)
        } else {
            const sect = sections.find(s => s.sect === parent)
            if(sect) {
                sect.items.push(new LineItem(line))
            }
        }
    })

    const mrcSections = sections.map((s,idx) => {
        return (
            <MRCSection key={idx} data={s} />
        )
    })

    return (
        <div className="contract">
            {mrcSections}
        </div>
    )
}
