import { JMRC } from "../rebrowse/jmrc";
import { LineItem } from "../rebrowse/line-item";
import { DDDataPoint } from "./DefinedData";
import { utils } from "./utils"; 

export class ExtractStrategy {
	tag = "";
	name = "";
	mrcHeading = "";
	text = false;
	originalHeadings : string[] = [];

	static JustTag( tag : string ) : ExtractStrategy {
		const result = new ExtractStrategy();
		result.tag = tag;
		return result;
	}
	static NameInHeading( name : string, mrcHeading : string ) : ExtractStrategy {
		const result = new ExtractStrategy();
		result.name = name;
		result.mrcHeading = mrcHeading;
		return result;
	}
	static NameInOriginals( name : string, originals : string[] ) : ExtractStrategy {
		const result = new ExtractStrategy();
		result.name = name;
		originals.forEach( str => { result.originalHeadings.push( utils.aggressiveTidy( str )); });
		return result;		
	}
	static TextOfHeading( mrcHeading : string ) {
		const result = new ExtractStrategy();
		result.mrcHeading = mrcHeading;
		result.text = true;
		return result;
	}
}

export class JMRCExtractor {
	jmrc: JMRC.Root;
	verbose = false;
	constructor( jmrc : JMRC.Root ) {
		this.jmrc = jmrc;
		this.verbose = false;
	}
	placingBrokerChannel = () => {
		return this.jmrc.control.placingBrokerChannel;
	}
	extract = ( strategies : ExtractStrategy[] ) : string => {
		for( let i = 0, len = strategies.length; i < len; i++ ) {
			const s = strategies[i];
			if( s.tag ) {
				const points = this.getDataPoints().filter( (dp) => { return dp.tag == s.tag });
				if( this.verbose )	console.log( `${points.length} points for ${s.tag}`);
				if( points.length > 0 ) {
					if( this.verbose )	console.log( points );
					return points[0].value;
				}
			}
			if( s.mrcHeading && s.text ) {
				const liArray = this.jmrc.MRCContract.lineItems.filter( li => { return li.mrcHeading == s.mrcHeading } );
				if( liArray.length ) {
					const li = liArray[0];
					const parts : string[] = [];
					li.elements.forEach( elt => {
						if( elt.text ) {
							parts.push( elt.text );
						}
					})
					let result : string = parts.join( "\n" );
					li.placeholders.forEach( ph => {
						const grail = `[[${ph.name}]]`;
						result = result.replaceAll( grail, ph.value );
					});
					if( result ) {
						return result;s
					}
				}
			}
			if( s.mrcHeading && s.name ) {
				const liArray = this.jmrc.MRCContract.lineItems.filter( li => { return li.mrcHeading == s.mrcHeading } );
				if( this.verbose )	console.log( `${liArray.length} liArray match ${s.mrcHeading} looking for ${s.name}`);
				let result = "";
				liArray.forEach( li => {
					if( !result ) {
						li.placeholders.forEach( ph => {
							if( !result && ph.name == s.name ) {
								result = ph.value;
							}
						});
					}
				});
				if( result ) {
					return result;s
				}
			}
			if( s.originalHeadings.length && s.name ) {
				let result = "";
				s.originalHeadings.forEach( original => {
					if( !result ) {
						const liArray = this.jmrc.MRCContract.lineItems.filter( li => { return utils.aggressiveTidy( li.originalHeading ) == original } );
						if( this.verbose )	console.log( `${liArray.length} liArray match ${s.mrcHeading} looking for ${original}`);
						liArray.forEach( li => {
							if( !result ) {
								li.placeholders.forEach( ph => {
									if( !result && ph.name == s.name ) {
										result = ph.value;
									}
								});
							}
						});
					}
				});
				if( result ) {
					return result;s
				}
			}
		}
		return "";
	}
	getDataPoints = () : DDDataPoint[] => {
		const result: DDDataPoint[] = [];
		this.jmrc.MRCContract.lineItems.forEach( li => {
			li.placeholders.forEach( ph => {
				if( ph.name.substring(0,11) == "DefinedData") {
					DDDataPoint.fromPlaceholder( li.mrcHeading, ph ).forEach( dp => {
						result.push( dp );	
					});
				}
			});
		});
		return result;
	}
}