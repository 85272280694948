import React, { useState, useEffect, ReactElement } from 'react'
import { Modal, Col, Row, Button, Tab, Tabs, Form } from 'react-bootstrap'
import { ExtractStrategy, JMRCExtractor } from '../../helpers/JMRCExtractor';
import { SummaryItem } from '../../helpers/SummaryItem';
import { Query, whitespace, WhitespaceAPI } from "../../helpers/whitespace";
import { JMRC } from "../../rebrowse/jmrc";
import { utils} from "../../helpers/utils";

export const DecsOnFac = () => {
	const [loading, setLoading] = useState("");
	const [facilities, setFacilities] = useState<any[]>([]);
	const [facilityPair, setFacilityPair] = useState("");
	const [facilityID, setFacilityID] = useState("");
	const [facilityName, setFacilityName] = useState("");
	const [summaries, setSummaries] = useState<SummaryItem[]>([]);
	const MyDataset : any[] = [];

	useEffect(() => {
        refreshFacilityRisk()
    }, [])

	const handleChange = (event) => {
        if( event.target.name === "facilityPair" ) {
			const value = event.target.value;
			const pos = value.indexOf( "/");
			
			setFacilityPair( value );
            setFacilityID( value.substring( 0, pos ) );
			setFacilityName( value.substring( pos + 1 ) );
			fetchDeclarations( value.substring( 0, pos ), "", "" );
        }
    }

	const refreshFacilityRisk = () => {
        const url = `/api/facilityNames`;
        WhitespaceAPI.get( url ).then( response => {
			console.log( response.data );
			setFacilities( response.data );			
        });
	}

	let declarations:any[] = [];

	const fetchDeclarations = ( id, last, from ) => {
		const payload = { facilities: [ id ], statuses: ["Signed"] };
		let url = `/api/summary`;
		if( last && from ) {
			url += `?last=${last}&from=${from.replaceAll(" ", "%20")}`;
		} else {
			setLoading( `Loading declarations ...`);
			setSummaries( [] );
			declarations.length = 0;
		}
		WhitespaceAPI.post( url, payload ).then( response => {
			declarations = [...declarations, ...response.data];
			setLoading( `${declarations.length} declarations loaded...`);
			if( response.data.length == 60 ) {
				const lastitem = response.data[ 59 ];
				fetchDeclarations( id, lastitem.rootID, lastitem.updatedAt);
			} else {
				setSummaries( declarations.map( x => { return new SummaryItem( x ); } ) );
				setLoading("");
			}
		});
	}

	const runDownload = async () => {
		MyDataset.length = 0;
		setLoading( "Fetching risk detail...");
		await Promise.all( summaries.map( ( summary ) => { 
			fetchData( summary.rootID + "::SIGNED" ); 
		}));
	}

	const fetchData = async ( id ) => {
		const url = `/api/risks/${id}`;		
		WhitespaceAPI.get( url ).then( response => {
			const x : JMRC.Root = response.data;
			const extractor = new JMRCExtractor( x );
			const output = { umr: x.control.umr, id: x._id, insuredName: x.control.insuredName, premium : "", inception_date: ""};
	
			let strategy : ExtractStrategy[] = [];
			strategy = [
				ExtractStrategy.JustTag( "Premium_Amount" ),
				ExtractStrategy.NameInHeading( "Amount1", "Premium" ),
				ExtractStrategy.NameInOriginals( "Amount1", [ "PREMIUM / RATES (100% ANNUAL)", "ANNUAL REINSURANCE PREMIUM: " ] )
			]
			output.premium = extractor.extract( strategy );
	
			strategy = [
				ExtractStrategy.JustTag( "Inception_Date"),
				ExtractStrategy.NameInHeading( "Date1", "Period")
			]
			output.inception_date = extractor.extract( strategy );
			MyDataset.push( output );
			if( MyDataset.length == summaries.length ) {
				setLoading( "");
				downloadTextFile();
			} else {
				setLoading( `${MyDataset.length} of ${summaries.length} fetched`);
			}
		});
	}

	const downloadTextFile = () => {
		const lines: string[] = [];
		const d = ",";
		lines.push( `UMR${d}Insured${d}Premium${d}Inception${d}IC` );
		MyDataset.forEach( item => {
			const parts = [ item.umr, item.insuredName, item.premium, item.inception_date, item.id ];
			lines.push( parts.map( p => { return utils.prepareForCSV( p ) } ).join( d ) );
		});
		const content = lines.join( "\n");
		console.log( content );
		const link = document.createElement('a');
		const file = new Blob( [content], {
			type: "text/plain"
		});
		link.href = URL.createObjectURL( file );
		link.download = makeFilename();
		link.click()
	}

	const makeFilename = () : string => {
		const parts = facilityName.match( /[A-Z0-9]+/gi );
		if( parts ) {
			return parts.join( "_") + ".csv";
		} else {
			return "DecsOnFac.csv";
		}
	}


	return (
		<>
		<Form.Control
			as="select"
			className="my-1"
			onChange={handleChange.bind(this)}
			name="facilityPair"
			value={facilityPair}
			custom>
			<option value=''>Select ...</option>
			{facilities.map((fac, idx) => <option value={`${fac.rootID}/${fac.insuredName}`} key={idx}>{fac.insuredName}</option>)}
		</Form.Control>
		{loading != "" &&
		<div className='WarningBanner'>{loading}</div>
		}
		{summaries.length > 0 &&
		<div className='Above12 Below12'>
		<Button onClick={runDownload}>Download {summaries.length} Risks</Button>
		</div>
		}
		{summaries.map((summary,idx) => <div key={idx}>
			{summary.umr} {summary.insuredName}
			<div className='XSmallText LightGray'>{summary.rootID}</div>
		</div>)}
		</>
	)
}