import { Analytics } from './Analytics/Analytics'
import { BrokerToBroker } from './BrokerToBroker/BrokerToBroker'
import { Reinsurance } from './Reinsurance/Reinsurance'
import { Dashboard } from './Dashboard/Dashboard'
import { PolicySearch } from './PolicySearch/PolicySearch'
import { UnderscoreRisks } from './UnderscoreRisks/UnderscoreRisks'
import { EOC } from './EOC/EOC'
import { Experiment } from './Experiment/Experiment'
import { GPMMaker } from './GPMMaker/GPMMaker'
import { Testers } from './Testers/Testers'
import { CDRCaller } from './CDRCaller/CDRCaller'
import { MIReport } from './MIReport/MIReport'
import { Decorator } from './Decorator/Decorator'
import { Contents } from './Contents/Contents'
import { RulebookCaller } from './RulebookCaller/RulebookCaller'
import { Invoicing } from './Invoicing/Invoicing'
import { MyCompany } from './MyCompany/MyCompany'
import { WholeMarket } from './WholeMarket/WholeMarket'
import { VerticalCombiner } from './VerticalCombiner/VerticalCombiner'
import { StampIDs } from './StampIDs/StampIDs'
import { DecsOnFac } from './DecsOnFac/DecsOnFac'
import { LabelledRisks } from './LabelledRisks/LabelledRisks'
import { CombinedEOC } from './CombinedEOC/CombinedEOC'
import { ApplyTemplate } from './ApplyTemplate/ApplyTemplate'

export const modules = () => {
    return [
        {
            order: 0,
            id: 'contents',
            beta: false,
            component: Contents,
            display: 'Contents',
            header: 'Contents',
            icon: 'home'
        },
        {
            order: 1,
            id: 'dashboard',
            beta: false,
            component: Dashboard,
            display: 'Dashboard',
            header: 'Whitespace Risks Overview',
            icon: 'home'
        },
        {
            order: 2,
            id: 'analytics',
            beta: false,
            component: Analytics,
            display: 'Analytics',
            header: 'Visualise your Whitespace Data',
            icon: 'graph'
        },
        {
            order: 3,
            id: 'policy-search-tool',
            beta: false,
            component: PolicySearch,
            display: 'Policy Search',
            header: 'Policy Search Tool',
            icon: 'search'
        },
        { // CLIENT SPECIFIC MODULE
            order: 4,
            id: 'underscorerisks',
            beta: false,
            component: UnderscoreRisks,
            display: 'Underscore',
            header: 'Signed Underscore Risks',
            icon: 'book'
        },
        {
            order: 5,
            id: 'eoc',
            beta: false,
            component: EOC,
            display: 'Export EOC',
            header: 'Export Evidence of Cover',
            icon: 'book'
        },
        {
            order: 7,
            id: 'reinsurance',
            beta: true,
            component: Reinsurance,
            display: 'Reinsurance',
            header: 'Request reinsurance for a risk',
            icon: 'book'
        },
        {
            order: 8,
            id: 'brokertobroker',
            beta: false,
            component: BrokerToBroker,
            display: 'Broker to Broker',
            header: 'Send Risk to London Broker and Track Progress',
            icon: 'book'
        },
        {
            order: 9,
            id: 'experiment',
            beta: true,
            component: Experiment,
            display: 'Experiment',
            header: 'Experiments (don\'t install for users)',
            icon: 'book'
        },
        {
            order: 10,
            id: 'gpm',
            beta: false,
            component: GPMMaker,
            display: 'GPM',
            header: 'GPM from Platform Risk',
            icon: 'book'
        },
        {
            order: 11,
            id: 'testers',
            beta: false,
            component: Testers,
            display: 'Testers',
            header: 'Screens to help Testers',
            icon: 'book'
        },
        {
            order: 12,
            id: 'cdr',
            beta: false,
            component: CDRCaller,
            display: 'CDR',
            header: 'Lloyd\'s Core Data Record API',
            icon: 'book'
        },
        {
            order: 13,
            id: 'mireport',
            beta: false,
            component: MIReport,
            display: 'MI Report',
            header: 'MI Report (pre Sept 2021)',
            icon: 'book'
        },
        {
            order: 14,
            id: 'decorator',
            beta: false,
            component: Decorator,
            display: 'Decorator',
            header: 'Decorator',
            icon: 'book'
        },
        {
            order: 15,
            id: 'rulebookcaller',
            beta: false,
            component: RulebookCaller,
            display: 'Rulebook Caller',
            header: 'Rulebook Caller',
            icon: 'book'
        },
        {
            order: 16,
            id: 'invoicing',
            beta: false,
            component: Invoicing,
            display: 'Invoicing',
            header: 'Invoicing',
            icon: 'book'
        },
        {
            order: 17,
            id: 'mycompany',
            beta: false,
            component: MyCompany,
            display: 'My Company',
            header: 'My Company',
            icon: 'book'
        },
        {
            order: 18,
            id: 'wholemarket',
            beta: false,
            component: WholeMarket,
            display: 'Whole Market',
            header: 'Whole Market',
            icon: 'book'
        },
        {
            order: 19,
            id: 'verticalcombiner',
            beta: false,
            component: VerticalCombiner,
            display: 'Vertical Combiner',
            header: 'Vertical Combiner',
            icon: 'book'
        },
        {
            order: 20,
            id: 'stampids',
            beta: false,
            component: StampIDs,
            display: 'Stamp IDs',
            header: 'Stamp IDs',
            icon: 'book'
        },
        {
            order: 21,
            id: 'decsonfac',
            beta: false,
            component: DecsOnFac,
            display: 'Decs on Fac',
            header: 'Declarations on a Facility',
            icon: 'book'
        },
        {
            order: 22,
            id: 'labelledrisks',
            beta: false,
            component: LabelledRisks,
            display: 'Labelled Risks',
            header: 'Labelled Risks',
            icon: 'book'
        },
        {
            order: 22,
            id: 'combinedeoc',
            beta: false,
            component: CombinedEOC,
            display: 'Combined EOC',
            header: 'Combined EOC',
            icon: 'book'
        },
        {
            order: 23,
            id: 'applytemplate',
            beta: false,
            component: ApplyTemplate,
            display: 'Apply Template',
            header: 'Apply Template',
            icon: 'book'
        }
   ].filter(module => process.env.REACT_APP_ENABLED_MODULES?.split(' ').includes(module.id))
}