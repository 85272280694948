import { useState, useEffect } from 'react'
import { whitespace, Query } from '../../helpers/whitespace'
import { Modal, Col, Row, Button, Tab, Tabs, Form } from 'react-bootstrap'
// import DataTable from 'react-data-table-component'
import { NotificationManager } from 'react-notifications'
import { B2BViewAdvices } from './B2BViewAdvices'
import { B2BSendAdvice } from './B2BSendAdvice'
// import { Risk } from '../../components/Risk'
// import { utils } from '../../helpers/utils'
import { NoResultsFound } from '../../components/Loading'
import { riskFilter } from '../../helpers/riskFilter'
import { RiskGrid } from '../../components/RiskGrid'
import { PageLoading } from '../../components/Loading'
// import Tab from 'react-bootstrap/Tabs'
// import Tabs from 'react-bootstrap/Tabs'

export const ListDraftRisks = () => {
    const [fetchingData, setFetchingData] = useState(true)
    const [risks, setRisks] = useState<any[]>([])
    const [brokers, setBrokers] = useState<any[]>([])
    const [selectedRisk, setSelectedRisk] = useState<any>()
    const [showSTBMenu, setShowSTBMenu] = useState(false)
    const [selectedBroker, setSelectedBroker] = useState<any>({
        companyId: '',
        teamId: ''
    })
    // const [selectedBrokerId, setSelectedBrokerId] = useState<any>('')
    // const [selectedBrokerTeam, setSelectedBrokerTeam] = useState<any>()

    useEffect(() => {
        refreshRisksAdvices()
    }, [])

    const refreshRisksAdvices = () => {
        setRisks([])

        setFetchingData(true)
        const allRisks = whitespace(Query.GET, '/api/risks') //?status=NotFinalised')
        // const advices = whitespace(Query.GET, '/mapi/toBroker/listAllAdvices' )
        const brokers = whitespace(Query.GET, '/api/shared/corporate')
        Promise.all([allRisks, brokers]).then(response => {
            setFetchingData(false)
            setBrokers(response[1].data.filter(item => item.role === 'broker'))
            // setAllAdvices( response[1].data )
            setRisks(riskFilter.groupAllRisks(response[0].data, { method: 'NotFinalisedSingletons' }))
        })
    }

    const submitRisk = risk => {
        setSelectedRisk(risk)
        setShowSTBMenu(true)
    }

    const sendRiskToBroker = () => {
        const companyId = selectedBroker.companyId
        const teamId = selectedBroker.teamId

        if (!companyId || !teamId) {
            NotificationManager.warning("Please select broker and team first");
            return;
        }
        whitespace(Query.POST, `/mapi/toBroker/${companyId}/${teamId}/submit/${selectedRisk.id}`, {}).then(response => {
            typeof (response) !== 'undefined' && refreshRisksAdvices()
            typeof (response) === 'undefined' && NotificationManager.error('Could not submit to selected Broker and Team channel')
            setShowSTBMenu(false)
        })
    }

    const handleChange = (event) => {
        if( event.target.name === "companyId" ) {
            setSelectedBroker( { companyId : event.target.value, teamId : '' })
        } else {
            setSelectedBroker({
                ...selectedBroker,
                [event.target.name]: event.target.value
            })
        }
    }

    const STBMenu = () => {
        if (selectedRisk) return (
            <Modal show={showSTBMenu} onHide={() => setShowSTBMenu(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Send Risk to Broker</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <h4 className="mb-4">Risk Summary</h4>
                            <p><strong>Risk Name</strong> {selectedRisk.InsuredName}</p>
                            <p><strong>Limit Summary</strong> {selectedRisk.limitSummary}</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h4>Placing Broker</h4>
                            <Form.Control
                                as="select"
                                className="my-1"
                                onChange={handleChange.bind(this)}
                                name="companyId"
                                value={selectedBroker.companyId === '' ? '' : selectedBroker.companyId}
                                custom>
                                <option value=''>Select ...</option>
                                {brokers.map((broker, idx) => <option value={broker.companyId} key={idx}>{broker.name}</option>)}
                            </Form.Control>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col>
                            <h4>Placing Team</h4>
                            <Form.Control
                                as="select"
                                className="my-1"
                                onChange={handleChange.bind(this)}
                                name="teamId"
                                value={selectedBroker.team === '' ? '' : selectedBroker.team}
                                custom>
                                <option value=''>Select ...</option>
                                {brokers.map(broker => {
                                    if (broker.companyId === selectedBroker.companyId) {
                                        return broker.teams.map((team, idx) => <option value={team.teamId} key={idx}>{team.name}</option>)
                                    }
                                })}
                            </Form.Control>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => sendRiskToBroker()}>Send Risk to Broker</Button>
                </Modal.Footer>
            </Modal>
        )
    }


    return (
        <>
            {STBMenu()}
            {fetchingData && <PageLoading />}
            {!fetchingData &&
                <>
                    <h2 className='Above12'>{risks.length} Draft Risk{risks.length === 1 ? '' : 's'} <small>
                        <span className='MyIcon' onClick={() => refreshRisksAdvices()}>↺</span>&nbsp;
            </small></h2>
                    <RiskGrid input={risks} pickFn={submitRisk} singleLine={true} />
                </>
            }
        </>
    )
}

export const BrokerToBroker = () => {
    const [myDetails, setMyDetails] = useState<any>({})
    useEffect(() => {
        const call0 = whitespace(Query.GET, '/api/user/myDetails')
        Promise.all([call0]).then(response => {
            setMyDetails(response[0].data)
        })
    }, [])

    if (myDetails.role !== 'broker')
        return (<div className="page-notice"><NoResultsFound />Page only available to brokers</div>)

    return (
        <Tabs defaultActiveKey="sendRisk" id="uncontrolled-tab-example">
            <Tab eventKey="sendRisk" title="Send Risk">
                <ListDraftRisks />
            </Tab>
            <Tab eventKey="sendAdvice" title="Send Advice">
                <B2BSendAdvice />
            </Tab>
            <Tab eventKey="viewAdvices" title="View Advices">
                <B2BViewAdvices />
            </Tab>
        </Tabs>
    )
}
