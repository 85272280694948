import React, { useState, useEffect } from 'react'
import { whitespace, Query } from '../../helpers/whitespace'
import { Modal, Col, Row, Button } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { utils } from '../../helpers/utils'
import { propTypes } from 'react-bootstrap/esm/Image'
import './BrokerToBroker.css'
import { NotificationManager } from 'react-notifications'
import { RiskGrid } from '../../components/RiskGrid'
import { riskFilter } from '../../helpers/riskFilter'

export const B2BSendAdvice = () => {
    const [risks, setRisks] = useState<any[]>([])
    const [distinctRisks, setDistinctRisks] = useState<any[]>([])
    const [selectedRisk, setSelectedRisk] = useState<any>()
    const [showDialog,setShowDialog] = useState(false)
    const [activityMessage,setActivityMessage] = useState('')
    const [targetCompanyID, setTargetCompanyID] = useState('')
    const [targetTeamID, setTargetTeamID] = useState('')


    useEffect(() => {
        refreshData()
    }, [])

    const refreshData = () => {
        setRisks([])
        const risks = whitespace(Query.GET, '/api/risks')
        Promise.all([risks]).then(response => {
            setRisks( riskFilter.groupAllRisks( response[0].data ) )
            const reduced: any[] = []
            const decorated: any[] = []
            response[0].data.map( item => {
                item.rootID = utils.rootIC( item.id )
                decorated.push( item )
            })
            decorated.forEach( item => {
                const matching = reduced.filter( item2 => { return item2.rootID === item.rootID } )
                if( matching.length === 0 ) {
                    reduced.push( item )
                } else {
                    if( matching[0].createdAt < item.createdAt) {
                        matching[0] = item
                    }
                }
            })
            setDistinctRisks( reduced )
        })
    }

    /*
    "actors": [
        
        {
            "role" : "introducingBroker",
        "channels": [
            "blackpool_INTRODUCING"
        ]
        }
    ],
    */
   
    const pickIteration = ( risk ) => {
        setSelectedRisk( risk )

        whitespace(Query.GET, `/api/risks/${risk.id}`).then( response => {
            let companyID = '', teamID = ''
            const actors = response?.data?.actors
            console.log( 'actors', actors )
            if( actors ) {
                actors.forEach( itm => {
                    if( itm.role === 'introducingBroker' && itm.channels?.length ) {
                        const channel = itm.channels[0]
                        const parts = channel.split( '_')
                        companyID = parts[0].toUpperCase()
                        teamID = parts[1].toUpperCase()
                    }
                })
            }
            setTargetCompanyID( companyID )
            setTargetTeamID( teamID )
            setShowDialog(true)
        })      
    }

    const onChangeMessage = (event) => {
        setActivityMessage(event.target.value)
    }

    const sendAdvice = () => {
        if( !targetCompanyID || !targetTeamID ) {
            NotificationManager.error( 'Introducing team not found')
            return
        }
        whitespace(Query.POST, `/mapi/toBroker/${targetCompanyID}/${targetTeamID}/advice/${selectedRisk.id}`, {'activity': activityMessage}).then(response => {
            setSelectedRisk(null)
            if(response.status === 200) {
                NotificationManager.success('Advice sent to introducing broker')
            } else {
                NotificationManager.error('Could not send advice to introducing broker')
            }
        })
    }

    const SendAdviceDialog = () => {
        if( selectedRisk ) return (
          <Modal show={showDialog} onHide={() => setShowDialog(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Send Advice to Introducing Broker</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>Risk Summary</h4>
              <div className='DlgSubTitle'>Risk Name</div> {selectedRisk.InsuredName}<br/>
              <div className='DlgSubTitle'>UMR</div> {selectedRisk.umr}<br/>
              <div className='DlgSubTitle'>IC</div> {utils.rootIC(selectedRisk.id)}<br/>
              <div className='DlgSubTitle'>Status</div> {selectedRisk.Status}<br/>
                <div className='DlgSubTitle'>Created At</div> {utils.formatDateTime(selectedRisk.createdAt)}<br/>
                <div className='DlgSubTitle'>Full Document ID</div> {selectedRisk.id}<br/>
                <div className='DlgSubTitle'>Introducing Company / Team</div> {targetCompanyID} / { targetTeamID}<br/>
                {(!targetCompanyID || !targetTeamID) && 
                <div className='Above12 WarningText'>
                    This was not a risk submitted by another broker
                </div>
                }
                {targetCompanyID && targetTeamID && 
                <>
                <div className='DlgSubTitle'>Message</div> <input onChange={onChangeMessage}  className='FullWidth' type='text'></input><br/>
                <div className='Above12'>
                <Button className="btn-primary btn-sm" disabled={activityMessage.length < 3} onClick={() => sendAdvice()}>Send Advice</Button>
                </div>
                </>}
            </Modal.Body>
          </Modal>
        )
    }

    return (
        <>
        {SendAdviceDialog()}
        <h2 className='Above12'>{ distinctRisks.length } Risk{ distinctRisks.length === 1 ? '' : 's'} <small>
            <span className='MyIcon' onClick={() => refreshData()}>↺</span>&nbsp; 
            </small></h2>
            <RiskGrid input={risks} pickFn={pickIteration} />
        </>
    )
}