import React, { useState, useEffect } from 'react'
import { whitespace, Query } from '../helpers/whitespace'
import { getSessionDetails } from '../helpers/auth'
import { Modal } from 'react-bootstrap'
import { setMUID } from '../App'
import { CorporateLogo } from './CorporateLogo'

export const SUMOPicker = ({ switchTo }) : JSX.Element => {
    const session = getSessionDetails()
    const [ showPopup, setShowPopup ] = useState( false )
    const [ SUMOAccounts, setSUMOAccounts ] = useState<any>([])
    useEffect(() => {
        refreshData()
    }, [])

    const refreshData = () => {
        const call = whitespace(Query.POST, '/auth/token')
        Promise.all([ call ]).then( response => {
            if( response[0]?.data ) {
                if( response[0].data.userID ) {
                    setSUMOAccounts( [] )
                } 
                if( response[0].data.userIDs ) {
                    holdCorporates(response[0].data.userIDs)
                }
            }
        })
    }

    const holdCorporates = (userIDs) => {
        const call = whitespace(Query.GET, '/api/shared/corporate')
        const accounts : any[] = []
        Promise.all([ call ]).then( response => {
            if( response[0]?.data ) {
                const corps = response[0]?.data
                corps.map( (corp) => {
                    corp.members.map( (member ) => {
                        if( userIDs.includes( member.uniqueID ) ) {
                            const account = { companyId: corp.companyId, name: corp.name, uniqueID: member.uniqueID }
                            accounts.push( account )
                        }
                    })
                })
                setSUMOAccounts( accounts )
            }
        })
    }

    const handleSwitchTo = account => {
        setShowPopup(false)
        setMUID( account.uniqueID )
        // The switchTo function below is passed into this component from Navbar
        switchTo(account)
    }

    const AccountPopup = () => {
        return (
          <Modal show={showPopup} onHide={() => setShowPopup(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Choose Company</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {SUMOAccounts.map( (ac,idx) => (
                    <div className='MyLink' key={idx} onClick={() => handleSwitchTo(ac) }>{ac.name}</div>
                ))}
            </Modal.Body>
          </Modal>
        )
    }

    return SUMOAccounts.length > 1 ? (
        <>
        <div className='MyLink NavTiny' onClick={() => setShowPopup(true)}>{SUMOAccounts.length} Organisations</div>
        <AccountPopup />
        </>
    ) : <></>
}