import {JMRC} from '../rebrowse/jmrc'

export class DDPage {
    mrcHeading = "";
    parentID = "";
    points: DDDataPoint[] = [];
    constructor( p: DDDataPoint ) {
        this.mrcHeading = p.mrcHeading;
        this.parentID = p.parentID;
        this.points.push( p );
    }

    matchesOb = ( ob : any ) : boolean => {
        return this.mrcHeading === ob.mrcHeading && this.parentID === ob.parentID
    }

    matchForCopy = ( page: DDPage, debug = false ) : boolean => {
        if( page.mrcHeading != this.mrcHeading )   return false;
        if( page.points.length != this.points.length ) return false;

        if( debug ) {
            console.log( `matchForCopy cf ${this.parentID} and ${page.parentID}, both ${page.mrcHeading} ${page.points.length}`);
        }
        let retval = true;
        page.points.forEach( p => {
            const tparray = this.points.filter( tp => { return tp.tag == p.tag });
            if( tparray.length != 1 ) {
                retval = false;
                if( debug ) {
                    console.log( `matchForCopy cf ${tparray.length} tags match ${p.tag}`);
                }
            } else {
                const tp = tparray[0];
                if( p.value != tp.value && p.value != "???" ) {
                    retval = false;
                    if( debug ) {
                        console.log( `matchForCopy may not because target:${p.value}: source:${tp.value}:`);
                    }    
                }
            }
        });
        if( retval ) {
            console.log( `matchForCopy ok - match found`);
        }
        return retval;
    }

    static fromJMRC( jmrc : JMRC.Root ) : DDPage[] {
        const result: DDPage[] = [];
        const points = DDDataPoint.fromJMRC( jmrc );
        console.log( `${points.length} points`);
        points.forEach( p => {
            const page : DDPage = result.filter( page => { return page.matchesOb( p ); } )[0];
            if( page ) {
                page.points.push( p );
            } else {
                result.push( new DDPage( p ));
            }
        });
        return result;
    }
}

export class DDDataPoint {
    mrcHeading = "";
    name = "";
    parentID = "";
    tag = "";
    value = "";
    placeholder: JMRC.Placeholder;

    constructor( mrcHeading: string, parentID: string, tag: string, ph: JMRC.Placeholder ) {
        this.mrcHeading = mrcHeading;
        this.name = ph.name || "";
        this.parentID = parentID || "";
        this.tag = tag || "";
        this.value = ph.value || "";
        this.placeholder = ph;
    }

    static fromJMRC( jmrc : JMRC.Root ) : DDDataPoint[] {
        const result: DDDataPoint[] = [];
		jmrc.MRCContract.lineItems.forEach( li => {
			li.placeholders.forEach( ph => {
				if( ph.name.substring(0,11) == "DefinedData") {
					DDDataPoint.fromPlaceholder( li.mrcHeading, ph ).forEach( dp => {
						result.push( dp );	
					});
				}
			});
		});
		return result;
    }

    static fromPlaceholder( mrcHeading: string, ph : JMRC.Placeholder ) : DDDataPoint[] {
        const array: DDDataPoint[] = [];
        const parentID = ph.tags?.filter( tag => { return tag.startsWith( "Page:" ); })[0] || "";
        ph.tags?.forEach( tag => {
            if( !tag.startsWith( "Page:" ) )
            {
                const newPoint = new DDDataPoint( mrcHeading, parentID, tag, ph );
                array.push( newPoint );
            }
        });
        return array;
    }

    static getFirstMatchingValue( ddPoints : DDDataPoint[], tag : string ) : string {
        const matches = ddPoints.filter( item => { return item.tag == tag });
        if( matches.length ) {
            return matches[0].value;
        } 
        return "";
    }
}

export class DefinedData {
    /*
    static readMRC( mrc : JMRC.MRCContract ) : DDDataPoint[] {
        const result : DDDataPoint[] = [];

        mrc.lineItems.map( ( li ) => {
            li.placeholders.map( ( ph ) => {
                if( ph.name.startsWith( "DefinedData") ) {
                    const pageID = ph.tags?.filter( ( t ) => { return t.startsWith( "Page:")})[0].substring(5);
                    const pageTitle = li.tagPages?.filter( ( p ) => { return p.pageID == pageID })[0].title;
                    ph.tags?.map( ( t ) => {
                        if( t != "Page:" + pageID ) {
                            const dd = new DDDataPoint();
                            dd.mrcHeading = li.mrcHeading;
                            dd.name = ph.name;
                            dd.parentID = pageID || "";
                            dd.tag = t;
                            dd.value = ph.value;
                            result.push( dd );
                        }    
                    });
                }
            })
        });
        return result;
    }
    */
    static findFirst( ddpoints: DDDataPoint[], grail : any ) : DDDataPoint | null {
        const all = DefinedData.findAll( ddpoints, grail );
        if( all[0] ) {
            return all[0];
        }
        return null;
    }

    static findAll( ddpoints: DDDataPoint[], grail : any ) : DDDataPoint[] {
        let subset = [...ddpoints];
        if( grail.mrcHeading ) {
            subset = subset.filter( ( p ) => { return p.mrcHeading == grail.mrcHeading } );
        }
        if( grail.tag ) {
            subset = subset.filter( ( p ) => { return p.tag == grail.tag } );
        }
        return subset;
    }
}