import React, { useState, useEffect } from 'react'
import { whitespace, Query } from '../../helpers/whitespace'
import { Modal, Col, Row, Button } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { utils } from '../../helpers/utils'
import { propTypes } from 'react-bootstrap/esm/Image'
import './BrokerToBroker.css'
import { MRCViewer } from '../../rebrowse/MRCViewer'


const columns = [
    {
        name: 'Insured Name',
        selector: 'insuredName'
    },
    // {
    //     name: 'UMR',
    //     selector: 'umr'
    // },
    // {
    //     name: 'Created At',
    //     selector: 'createdAt'
    // },
    {
        name: 'Activity',
        selector: 'activityMessage'
    },
    {
        name: 'Advice Count',
        selector: 'adviceCount'
    },
    {
        name: 'Earliest',
        selector: 'earliestF'
    },
    {
        name: 'Latest',
        selector: 'latestF'
    },    
]

export const B2BViewAdvices = () => {
    const [allAdvices, setAllAdvices] = useState<any[]>([])
    const [summaries, setSummaries] = useState<any[]>([])
    const [selectedAdvice,setSelectedAdvice] = useState<any>(null)
    const [viewingMRC,setViewingMRC] = useState(false)

    useEffect(() => {
        refreshData()
    }, [])

    const refreshData = () => {
        setAllAdvices([])
        const advices = whitespace(Query.GET, '/mapi/toBroker/listAllAdvices')
        Promise.all([advices]).then(response => {
            if( response[0]?.data ) {
                setAllAdvices( response[0].data )
                const reduced: any[] = []
                const decorated: any[] = []
                response[0].data.map( item => {
                    const rootID = utils.rootIC( item._id )
                    const matching = reduced.filter( item2 => { return item2.rootID === rootID } )
                    if( matching.length === 0 ) {
                        item.rootID = rootID
                        reduced.push( { rootID: rootID, earliest: item.createdAt, latest: item.createdAt, 
                            activityMessage: item.activityMessage, insuredName: item.insuredName, adviceCount: 1 } )    
                    } else {
                        matching[0].adviceCount += 1
                        if( matching[0].latest < item.createdAt) {
                            matching[0].latest = item.createdAt
                            matching[0].activityMessage = item.activityMessage
                        }
                        if( matching[0].earliest > item.createdAt) {
                            matching[0].earliest = item.createdAt
                        }
                    }
                })
                reduced.forEach( item => {
                    item.activityMessage = item.activityMessage || '-'
                    item.earliestF = utils.formatDateTime( item.earliest )
                    item.latestF = utils.formatDateTime( item.latest )
                })
                setSummaries( reduced )
            }
        })
    }

    const showMRC = ( id ) => {
        console.log( 'showMRC', id )
        const advice = whitespace(Query.GET, `/api/documents/${id}`)
        Promise.all([advice]).then(response => {
            setSelectedAdvice( response[0].data )
            setViewingMRC(true)
        })
    }

    const ExpandedAdvice = ( { items, match }) => {
        const matches = items.filter( (item) => item._id.startsWith( match )).sort( ( x, y ) => {
            return( x.createdAt.localeCompare( y.createdAt ) )
        })
        return (
            <div className='ExpandedAdviceBlock'>
                { matches.map( (item, index) => (
                        <div><span onClick={() => showMRC( item._id )} className='MyLink'>{utils.formatDateTime(item.createdAt)}</span> {item.activityMessage || ''}</div>
                    ))
                }
            </div>
        )
    }

    const ExpandedSummary = ({ data }) => {
        return (
            <ExpandedAdvice items={allAdvices} match={data.rootID}/>
        )
    }

    if( viewingMRC ) {
        const divStyle = { fontSize: 'small', paddingRight:'6px' }
        return (
            <>
            <div className='FloatRight' style={divStyle} >
                <span className='MyIcon' onClick={() => {
                setViewingMRC(false)
            }}>☒</span>
            </div>
            <MRCViewer lineItems={selectedAdvice.mrcContract.lineItems}/>
            </>
        )
    } else {
        return (
            <>
            <h2 className='Above12'>{ summaries.length } Advice{ summaries.length === 1 ? '' : 's'} <small>
            <span className='MyIcon' onClick={() => refreshData()}>↺</span>&nbsp; 
            </small></h2>

            <DataTable
                        columns={columns}
                        data={summaries}
                        defaultSortField="insuredName"
                        defaultSortAsc={true}
                        expandableRows
                        expandableRowsComponent={<ExpandedSummary data={summaries} />}
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 50, 100, 500]}
                        // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        persistTableHead
                    />
            </>
        )
    }
}