import React, { useState, useEffect } from 'react'
import { whitespace, Query } from '../../../helpers/whitespace'
import { PageLoading } from '../../../components/Loading'
import { Bar } from 'react-chartjs-2'

const options = {
    title: {
        display: true,
        fontSize: 20,
        text: 'Aggregate Limits in USD By Date'
    },
    legend: false,
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            callback: function(label) {
                return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
        },
      ],
    },
}

export const QuoteToBindRatio = () => {
    const [risks, setRisks] = useState<any[]>([])

    useEffect(() => {
        whitespace(Query.GET, '/api/risks').then(risks => {
            typeof(risks) !== 'undefined' && setRisks(risks.data)
        })
    }, [])

    const generateData = () => {
        const graphData = {}

        const timeLimits = risks.map(risk => {
            return {
                date: risk.updatedAt.split(' ')[0],
                limit: +risk.limitSummary.split(' ').pop().replace(/,/g, '')
            }
        })

        timeLimits.map(data => {
            if(data.date in graphData) {
                graphData[data.date] += data.limit
            } else {
                graphData[data.date] = data.limit
            }
        })

        return {
            labels: Object.keys(graphData),
            datasets: [
              {
                data: Object.values(graphData),
                backgroundColor: 'rgb(255, 99, 132)',
              }
            ],
        }
    }

    return (
        <>
            {!risks.length && <PageLoading />}
            <div className={`Fadein-wrapper ${risks.length && 'load'}`}>
                <Bar data={generateData()} options={options} />
            </div>
        </>
    )
}