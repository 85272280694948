import dotenv from 'dotenv'
import React, { useState } from 'react'
import { modules } from '../modules/registrar'
import { Button } from 'react-bootstrap'
import { logout } from '../helpers/auth'
import { MyDetails } from './MyDetails' 
import { CorporateLogo } from './CorporateLogo' 
import { SUMOPicker } from './SUMOPicker'
// import { SUMOPicker } from './SUMOPicker' 

dotenv.config()

const renderNavLink = (module, currentModule, switchModule) => {
    return (
        <div 
            key={`key-${module.id}`}
            className={`Navigation-link Centered ${module.component === currentModule.component && 'selected'}`}
            onClick={() => switchModule(module)}
        >
            {module.display}
            {module.beta && <span className="Beta-tag">beta</span>}
        </div>
    )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Navbar = ({ currentModule, switchModule, triggerRefresh }) => {
  const [companyId, setCompanyId] = useState();
  const [collapsed, setCollapsed] = useState(false);

  const switchTo = (account) => {
    console.log(
      `switchTo ${account.name} ${account.companyId} ${account.uniqueID}`
    );
    setCompanyId(account.companyId);
    triggerRefresh(account.companyId);
  };
  // const forceRefresh = () => {
  //     const d = new Date()
  //     const refreshToken = `refresh${d.getTime()}`
  //     triggerRefresh( refreshToken )
  // }
  /*
            { collapsed && <div><span className="IconLink" onClick={() => setCollapsed(false)}>➡︎</span></div> }
            { !collapsed && 
                <>
                <div><span className="IconLink" onClick={() => setCollapsed(true)}>⬅︎</span></div>
    */

  return (
    <div className={collapsed ? "NavbarCollapsed" : "Navbar"}>
      {!collapsed && (
        <>
          <div className="LogoContainer">
            <CorporateLogo companyId={companyId} />
          </div>
          <div className="Centered">
            <MyDetails companyId={companyId} />
          </div>
          <div className="Centered">
            <SUMOPicker switchTo={(account) => switchTo(account)} />
          </div>
          {modules().map((module) => {
            return renderNavLink(module, currentModule, switchModule);
          })}
          <div className="Centered">
            <Button onClick={logout} className="Logout Above12 btn-danger">
              Logout
            </Button>
          </div>
          {/* <div className='Centered'><span className="MyLink NavTiny" onClick={() => forceRefresh()}>Refresh</span></div> */}
        </>
      )}
    </div>
  );
};