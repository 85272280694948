import React, { useState, useEffect, ReactElement } from 'react'
import { Modal, Col, Row, Button, Tab, Tabs, Form } from 'react-bootstrap'
import { Query, whitespace, WhitespaceAPI } from "../../helpers/whitespace";
import { JMRC } from "../../rebrowse/jmrc";
import { CombinedSets } from "../../helpers/CombinedSets";
import { CombinedSetUtils } from "../../helpers/CombinedSetUtils";
// import { CombinedSetUtils } from "../../helpers/CombinedSetUtils";
import { utils} from "../../helpers/utils";
import { DDDataPoint, DefinedData } from "../../helpers/DefinedData";
import dayjs from 'dayjs';
import { NotificationManager } from 'react-notifications'

/*
From: Hamish Greenwood <Hamish.Greenwood@mcgillpartners.com>
Date: Thursday, 17 November 2022 at 09:52
To: Clarke, Jonathan <Jonathan.Clarke@Whitespace.co.uk>
Cc: Wright, Charley <Charley.Wright@whitespace.co.uk>, Wilkinson, Jody <Jody.Wilkinson@whitespace.co.uk>
Subject: RE: MI help

Done in a consistent manner since 1st October 2022. The tags we have introduced are as follows:
UNIQUE MARKET REFERENCE
INSURED
INSURED ADDRESS
INSURED IDENTIFIER 
PERIOD-
LIMITS OF LIABILITY
TERRITORIAL LIMITS
PREMIUM
SLIP LEADER
SETTLEMENT DUE DATE
OVERSEAS BROKER
FEE PAYABLE BY CLIENT
TOTAL BROKERAGE
ORDER HEREON
BASIS OF WRITTEN LINES
BASIS OF SIGNED LINES
*/

export const LabelledRisks = () => {
	const [loading, setLoading] = useState("");
	const [fromDate, setFromDate] = useState("1 October 2022");
	const [labelList, setLabelList] = useState<any[]>([]);
	const [summaries, setSummaries] = useState<any[]>([]);
	const [selectedLabel, setSelectedLabel] = useState("");
	const MyDataset : any[] = [];
	let fromInceptionDate = "";
	const wsDatetimeFormat = 'YYYY-MM-DD HH:mm:ss'

	const Expected_Tags = [
		"Broker_Reference",
		"Insured_Name", "Reinsured_Name", "Original_Insured_Name",
		"Insured_Country", "Reinsured_Country",
		"Insured_Identifier",
		"Inception_Date", "Expiry_Date",
		"Coverage_Amount", "Excess_Amount",
		"Risk_Location_Country",
		"Premium_Amount","Premium_Period_Qualifier",
		"Contract_Leader_Name",
		"Settlement_Due_Date",
		"Producing_Broker_Name",
		"Fee_Payable_By_Client",
		"Brokerage_%", "Brokerage_Amount",
		"Order_%",
		"Basis_Of_Signed_Lines",
		"Basis_Of_Written_Lines"
	];

	useEffect(() => {
        refreshLabelList()
    }, [])

	const refreshLabelList = () => {
        const url = `/api/labels/teamLabels`;
		setLoading( "Loading labels...");
        WhitespaceAPI.get( url ).then( response => {
			console.log( response.data );
			setLoading( "");
			setLabelList( response.data );	
			const x = {};
			response.data.forEach( lbl => {
				const title = lbl.title;
				if( x[ title ] ) {
					x[ title ] += 1;
				} else {
					x[ title ] = 1;
				}
			});	
			const y: any[] = [];
			Object.keys( x ).forEach( key => { y.push( { label: key, count: x[key] } )} );
			setSummaries( y );
        });
	}

	const runDownload = ( doSigned : boolean ) => {
		const wsDatetimeFormat = 
		fromInceptionDate = dayjs(fromDate).format('YYYY-MM-DD HH:mm:ss');
		if( fromInceptionDate.substring(0,2) != "20") {
			NotificationManager.error( `Date ${fromDate} should be like 1 October 2022`);
			return;
		}
		MyDataset.length = 0;
		callSummaryByLabel( "", "", doSigned );
	}

	const callSummaryByLabel = ( last, from, doSigned ) => {
		let url = "/api/summary";	
		if( last && from ) {
			url += `?last=${last}&from=${from}`;
		}
		const payload = { "labels" : [ selectedLabel ] };
		WhitespaceAPI.post( url, payload ).then( response => {
			const returnedCount = response.data.length;
			console.log( `returnedCount ${returnedCount} from ${url}` );
			if( returnedCount == 0 ) {
				processDataSet( doSigned );
			} else {
				response.data.forEach( x => {
					const item = { rootID: x.rootID, fetched: 0, output: null };
					MyDataset.push( item );
				});
				setLoading( `Identified ${MyDataset.length} risks labelled ${selectedLabel}`);
				const lastItem = response.data[ returnedCount - 1 ];
				const last = lastItem.rootID;
				const from = encodeURI( lastItem.updatedAt );
				callSummaryByLabel( last, from, doSigned );
			}
		});
	}

	const processDataSet = ( doSigned ) => {
		setLoading( `Reading ${MyDataset.length} signed risks`);
		MyDataset.forEach( item => {
			fetchData( item, doSigned );
		});
	}

	const fetchData = async ( item, doSigned ) => {
		const id = `${item.rootID}::SIGNED`;
		const url = `/api/risks/${id}`;		
		WhitespaceAPI.get( url, { silent: true } ).then( response => {
			if( !response?.data ) {
				item.fetched = -1;
			} else {
				const x : JMRC.Root = response.data;
				const output = { umr: x.control.umr, id: x._id, insuredName: x.control.insuredName, inceptionDate : "", premium: "", ddValues : [""], lineAnalysis: null };
				const ddValues: string[] = [];
				const ddPoints = DDDataPoint.fromJMRC( x );
				output.premium = DDDataPoint.getFirstMatchingValue( ddPoints, "Premium_Amount");
				const inceptionDate = DDDataPoint.getFirstMatchingValue( ddPoints, "Inception_Date");
				if( inceptionDate ) {
					output.inceptionDate = dayjs( inceptionDate ).format( wsDatetimeFormat );
				}
				Expected_Tags.forEach( tag => {
					const myValues = ddPoints.filter( p => { return p.tag == tag }).map( p => { return p.value });
					const joinedValue = myValues.length == 0 ? "" : myValues.join( ", ");
					ddValues.push( joinedValue );
				});
				output.ddValues = ddValues;
				item.output = output;
				item.fetched = 1;
			}
			const finished = showLoadingProgress1();
			if( finished ) {
				if( doSigned ) {
					processSignedLineData( doSigned );
				} else {
					prepareForOutput( doSigned );
				}
			}
		});
	}

	const processSignedLineData = ( doSigned ) => {
		const subset = MyDataset.filter( itm => itm.fetched == 1 );
		setLoading( `Reading ${subset.length} signed risks which meet criteria`);
		subset.forEach( item => {
			fetchSignedLineData( item, doSigned );
		});
	}

	const fetchSignedLineData = async ( item, doSigned ) => {
		const url = `/api/lines/${item.rootID}/combinedSets`;		
		WhitespaceAPI.get( url, { silent: true } ).then( response => {
			if( !response?.data ) {
				item.fetched = -1;
			} else {
				const linesets: CombinedSets.RootObject[] = response.data;
				const analysis = CombinedSetUtils.analyse( linesets );
				item.output.lineAnalysis = analysis;
				item.fetched = 2;
			}
			const finished = showLoadingProgress2();
			if( finished ) {
				prepareForOutput( doSigned );
			}
		});
	}

	const showLoadingProgress1 = () => {
		let fetched = 0, missing = 0;
		const len = MyDataset.length;
		for( let i = 0; i < len; i++ ) {
			const item = MyDataset[i];
			if( item.fetched == 1 ) 	fetched += 1;
			if( item.fetched == -1 ) 	missing += 1;
		}
		setLoading( `${fetched} loaded, ${missing} not signed of ${len} total`);
		return len == ( fetched + missing );
	}
	const showLoadingProgress2 = () => {
		let fetched = 0, remaining = 0;
		const len = MyDataset.length;
		for( let i = 0; i < len; i++ ) {
			const item = MyDataset[i];
			if( item.fetched == 2 ) 	fetched += 1;
			if( item.fetched == 1 ) 	remaining += 1;
		}
		setLoading( `${fetched} loaded signed lines, ${remaining} remaining of ${fetched + remaining} total`);
		return remaining == 0;
	}

	const prepareForOutput = ( doSigned ) => {
		const signedSet = MyDataset.filter( ( item ) => { return item.fetched >= 1; }).filter( item => { return item.output.inceptionDate >= fromInceptionDate } );
		const len = signedSet.length;
		if( len == 0 ) {
			setLoading( `No signed risks for label ${selectedLabel}`);
			return;
		}		
		if( doSigned ) {
			outputSignedData( signedSet, doSigned )
		} else {
			outputContractData( signedSet, doSigned );
		}
	}

	const outputSignedData = ( signedSet, doSigned ) => {
		setLoading( `Downloading signed line for ${signedSet.length} risks with label ${selectedLabel}`);
		const lines: string[] = [];
		const d = ",";
		lines.push( `UMR${d}Insured${d}Premium Currency${d}Premium Amount${d}USD Rate${d}USD Premium${d}Carrier${d}Business Unit${d}SignedPerc${d}Carrier Premium${d}IC`);
		signedSet.forEach( x => {
			const item = x.output;
			const prefix = [ 
				item.umr, item.insuredName,
				utils.parseAmount(item.premium)?.currency || "",
				utils.parseAmount(item.premium)?.figure?.toString() || "", 
				"", "" 
			];
			if( item.lineAnalysis && item.lineAnalysis.length ) {
				item.lineAnalysis.forEach( a => {
					let parts: string[] = [];
					parts = parts.concat( prefix );
					parts = parts.concat( [ a.company, a.businessUnit, a.signedLinePercentageString, "" ] );
					parts.push( item.id );
					lines.push( parts.map( p => { return utils.prepareForCSV( p ) } ).join( d ) );
				});
			} else {
				let parts: string[] = [];
				parts = parts.concat( prefix );
				parts = parts.concat( [ "", "", "", "" ] );
				parts.push( item.id );
				lines.push( parts.map( p => { return utils.prepareForCSV( p ) } ).join( d ) );
			}
		});
		downloadData( makeFilename( doSigned ), lines );
	}

	const outputContractData = ( signedSet, doSigned ) => {
		setLoading( `Downloading contract data for ${signedSet.length} risks with label ${selectedLabel}`);
		const lines: string[] = [];
		const d = ",";
		lines.push( `UMR${d}Insured${d}` + Expected_Tags.join( d ) + `${d}IC`);
		signedSet.forEach( x => {
			const item = x.output;
			const parts = [ item.umr, item.insuredName ];
			item.ddValues.forEach( val => {
				parts.push( val );
			});
			parts.push( item.id )
			lines.push( parts.map( p => { return utils.prepareForCSV( p ) } ).join( d ) );
		});
		downloadData( makeFilename( doSigned ), lines );
	}

	const downloadData = ( filename, lines) => {
		const content = lines.join( "\n");
		const link = document.createElement('a');
		const file = new Blob( [content], {
			type: "text/plain"
		});
		link.href = URL.createObjectURL( file );
		link.download = filename;
		link.click()
	}

	const makeFilename = ( doSigned ) : string => {
		const parts = selectedLabel.match( /[A-Z0-9]+/gi );
		const suffix = doSigned ? "_ByUnderwriter" : "";
		if( parts ) {
			return parts.join( "_") + suffix + ".csv";
		} else {
			return `LabelledRisks${suffix}.csv`;
		}
	}

	const onSelectLabel = (event) => {
		const value = event.target.value;
		setSelectedLabel( value );
    }

	const onChangeFromDate = event => {
		setFromDate( event.target.value )
	}

	return (
		<>
		{labelList.length > 0 &&
		<h2>{labelList.length} labels applied - {summaries.length} distinct labels</h2>
		}		
		{summaries.length > 0 &&
			<Form.Control
				as="select"
				className="my-1"
				onChange={onSelectLabel}
				name="selectedLabel"
				value={selectedLabel}
				custom>
				<option value=''>Select ...</option>
				{summaries.map((s, idx) => <option value={s.label} key={idx}>{s.label} - {s.count} risks</option>)}
			</Form.Control>	
		}
		{ selectedLabel != "" && 
			<div className='Above12 Below12'>
				Inception Date from&nbsp; 
				<input type="text" placeholder="dd MMM yyyy" aria-label="Search Input" value={fromDate} onChange={onChangeFromDate} />
				&nbsp;
			<Button onClick={() => {runDownload(false);}}>Contract Data</Button>&nbsp;
			<Button onClick={() => {runDownload(true);}}>Signed Line Data</Button>
			{/* &nbsp;<Button onClick={clickTest1}>Test1</Button> */}
			</div>	
		}
		{loading != "" &&
		<div className='WarningBanner'>{loading}</div>
		}
		</>
	)
}