import React, { useState, useEffect, ReactElement } from 'react'
import DataTable from 'react-data-table-component'
import { utils } from '../helpers/utils'
import { riskFilter } from '../helpers/riskFilter'

const RiskColumns = [
    {
        name: 'Insured Name',
        selector: 'InsuredName',
        sortable: true,
    },
    {
        name: 'UMR',
        selector: 'umr',
        sortable: true,
    },
    {
        name: 'Status',
        selector: 'Status',
        sortable: true,
    },
    {
        name: 'Earliest',
        selector: 'earliest',
        sortable: true,
    },
    {
        name: 'Latest',
        selector: 'latest',
        sortable: true,
    },
]

export const RiskGrid = ( { input, pickFn, singleLine = false } ) => {
    const [grail, setGrail] = useState('')
    const [summaries, setSummaries] = useState<any>( [] )

    useEffect(() => {
        setSummaries( riskFilter.match( input, grail ) )
    }, [ input ])

    const onChange = (event) => {
        const value = event.target.value
        setGrail( value )
        setSummaries( riskFilter.match( input, value ) )
    }

    const ExpandedSummary = ({ data }) => {
        return (
            <ExpandedRisk items={data}/>
        )
    }
    
    const ExpandedRisk = ( { items }) => {
        return (
            <div className='ExpandedAdviceBlock'>
                { items.iterations.map( (item, index) => (
                        <div id={index}><span className='MyLink' onClick={() => pickFn(item)} >{utils.formatDateTime(item.riskdate)}&nbsp;
                         {item.Status || ''}</span></div>
                    ))
                }
            </div>
        )
    }
    const rowClicked = row => {
        if( singleLine )
            pickFn(row.iterations[0])
    }

    return (
        <>
        {/* <div>Search for <input value={grail} onChange={onChange} placeholder='UMR or Insured Name' /></div> */}
        <DataTable
                    columns={RiskColumns}
                    data={summaries}
                    defaultSortField="InsuredName"
                    defaultSortAsc={true}
                    expandableRows={!singleLine}
                    expandableRowsComponent={<ExpandedSummary data={summaries} />}
                    onRowClicked={rowClicked}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 50, 100, 500]}
                    // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    persistTableHead
                    className={singleLine ? 'RiskGridSingleLine' : 'RiskGridExpandable'}
                />
        </>
    )
}



/*
export const Titlebar = ({ module }) => {
    const session = getSessionDetails()
    return (
        <div className="Titlebar">
            {module.header}
            <div className="Session-info">
                👤 {session.account.userName}
            </div>
        </div>
    )
}
*/