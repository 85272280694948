import { CLIENT } from "../../constants/client"

export const Contents = () => {
    const renderContents = () => {
        const contents = CLIENT.contents.length ? CLIENT.contents : [
            "This toolkit provides additional functionality for the users of the Whitespace Platform."
        ]
        return contents.map( item => <div>{item}<p/></div> )
        
    }

    return (
        <>
        { renderContents() }
        </>
    )
}