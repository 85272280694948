import React, { useState, useEffect } from 'react'
import './Experiment.css'
import { whitespace, Query } from '../../helpers/whitespace'
import { CorporateLogo } from '../../components/CorporateLogo'

const Pill = ( { value } ) => {
    return ( <div className='Pill'>{value}</div> )
}
const PeopleList = ( props ) => {
    const people = [ 
        'Jonathan Clarke', 'Marcus Broome', 'Alex Mundy', 'Alex Baines', 
        'Alecsandria Missungu', 'Hussein Ali', 'David Harrison', 'Dave Pay', 'Jahangir Alom', 'Rohit Natarajan', 'Anne McKenna', 'Anna Farmer',
        'Jody Wilkinson'
    ]

    const [ filter, setFilter ] = useState( props.filter ) 
    // useEffect(() => {
    //     setFilter(props.filter)
    // }, [props.filter])

    const fix = ( itm ) => {
        return itm // .replace( ' ', '_' )
    }

    return (
        <>
        <div className='QuietBorder'>
        <h2>{people.length} People</h2>
        <div>
        { people.sort( ( x, y ) => { return x.localeCompare( y ) })
        .map( itm => { return <Pill value={itm}/> } ) }
        </div>
        <div className='ClearBoth'></div>
        <h2>Filter on {filter}</h2>
        <div>Favourite searches 
            &nbsp;<span className='MyLink' onClick={ () => setFilter( 'Dav') }>Dav</span>
            &nbsp;<span className='MyLink' onClick={ () => setFilter( 'Ann') }>Ann</span>
        </div>
        <input type='text' value={filter} onChange={(e) => setFilter(e.target.value)}/>
        <div className='Above12'>
        { people
            .filter( itm => itm.toLowerCase().includes( filter.toLowerCase() ) )
            .sort( ( x, y ) => { return x.localeCompare( y ) })
            .map( itm => { return <Pill value={itm}/> } ) }
        </div>
        <div className='ClearBoth'></div>
        </div>
        </>
    )
}

export const CorporateIcon = () => {
    return (
        <div className='QuietBorder'>
            <div className='Width100'>
                <CorporateLogo companyId='' />
            </div>
        </div>
    )
}

export const Experiment = () => {


    return (
        <>
        <CorporateIcon />
        <PeopleList filter={'al'}/>
        </>
    )
}