/**
 * Average transaction duration
 */

import React, { useState, useEffect } from 'react'
import { whitespace, Query } from '../../../helpers/whitespace'
import { PageLoading } from '../../../components/Loading'
import { Bar } from 'react-chartjs-2'

export const AverageTransactionDuration = () => {
    return (
        <>
            Infographic
        </>
    )
}