import React, { useState, useEffect, ReactElement } from 'react'
import { Session, Stamp } from "../../Session";
import { Button, Container, Row, Col } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { WhitespaceAPI } from '../../helpers/whitespace'
import { Modal } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications'
import { utils } from '../../helpers/utils';

/*
import { STID, STIDDoc } from './STIDObjects'
export class STID {
	category = "";
	categoryNumber = "";
	categoryExtra = "";
	splitPercentage = "";
}

export class STIDDoc {
	_id = "";
	_rev = "";
	channels: string[] = [ "shared" ];
	createdAt = "";
	updatedAt = "";
	type = "RWStampIdentifiers";
	identifiers: STID[] = [];
}
*/

const CorpSummary = ( { stamp } ) => {
	return (
		<>
		<div>{stamp.stampType} / {stamp.bureauMarket} / {stamp.bureauMarketCode} / {stamp.bureauSubMarket} / {stamp.businessUnit}</div>
		<div className='LightGray '>{stamp.uniqueID || "No UniqueID"}</div>
		</>
	)
}



const STIDSummary = ( { stid } ) => {
	if( !stid.identifiers || stid.identifiers.length == 0 ) {
		return ( 
			<div>{stid.message || "No identifiers"}</div>
		)
	}
	if( stid.identifiers.length == 1 ) {
		return ( <STIDOneIdentifier i={stid.identifiers[0]} /> )
		// const i = stid.identifiers[0];
		// let summary = `${i.stampType || "-"} / ${i.stampCode || "-"} / ${i.stampPseudonym || "-"}`
		// if( i.splitPercentage ) {
		// 	summary += ` / ${i.splitPercentage}%`
		// }
		// return (
		// 	<div>{summary}</div>
		// )
	}
	else {
		return (
			<>
			<div>{stid.identifiers.length} ids:</div>
			{stid.identifiers.map( i => {
				return( <STIDOneIdentifier i={i} /> )
			})}
			</>
		)
	}
}

const STIDOneIdentifier = ( { i } ) => {
	let summary = `${i.category || "-"} / ${i.categoryNumber || "-"} / ${i.categoryExtra || "-"}`
	if( i.splitPercentage ) {
		summary += ` / ${i.splitPercentage}%`
	}
	return (
		<div>{summary}</div>
	)
}

export const StampIDs = () => {
	const [mayNotMessage, setMayNotMessage] = useState("");
	const [stamps, setStamps] = useState<any[]>([])
	const [ showDialog, setShowDialog ] = useState(false)
	const [selectedStamp, setSelectedStamp] = useState<any>( {} )
	const [stidJSON, setStidJSON] = useState( "");
	const [sayuserMessage, sayuser] = useState("");
	const columns = [
		{
			name: 'StampType / BureauMarket / BureauMarketCode / BureauSubMarket / BusinessUnit',
			width: "45%",
			selector: 'corporateSummary'
		},
		{
			name: '',
			width: "10%",
			selector: 'editbutton'
		},
		{
			name: 'Category / CategoryNumber / CategoryExtra / SplitPercentage',
			width: "45%",
			selector: 'stidSummaryBlock'
		}
	]

	useEffect(() => {
		fetchStamps();
	},[])

	const filledSet: any[] = [];

	const fetchStamps = async () => {
		setMayNotMessage( "");
		if( Session.corporate.role != "underwriter") {
			setMayNotMessage(`Access denied because your role is ${Session.corporate.role}`);
			return;
		}
		if( !Session.corporate.admins.includes( Session.userDetails.username ) ) {
			setMayNotMessage(`Access denied because ${Session.userDetails.username} is not among the ${Session.corporate.admins.length} admins for ${Session.corporate.companyId}`);
			return;
		}
		
		filledSet.length = 0;
		Session.corporate.stamps.forEach( stamp => {
			const s : any = stamp;
			// s.corporateSummary = `${s.stampType} / ${s.bureauMarket} / ${s.bureauMarketCode} / ${s.bureauSubMarket} / ${s.businessUnit}`
			s.corporateSummary = <CorpSummary stamp={s} />
			s.stid = { _id: "Missing", identifiers : [] };
			s.stidSummary = "???";
			s.stidSummaryBlock = <STIDSummary stid={{message:"???"}} />
			s.editbutton = <CorpEditButton stamp={s}  />
			filledSet.push( s );
		});
		filledSet.forEach( s => {
			fillSTID( s );
		})
	}

	const fillSTID = ( s ) => {
		WhitespaceAPI.get( `/api/documents/${s.uniqueID}::STID`, { silent: true } ).then( response => {
			if( response ) {
				if( response.data ) {
					const stid = response.data;
					s.stid = stid;
					s.stidSummary = describeSTID( stid );
					s.stidSummaryBlock = <STIDSummary stid={stid} />
				} else {
					s.stidSummary = "Missing"
					s.stidSummaryBlock = <STIDSummary stid={{message:"Missing"}} />

				}
			} else {
				s.stidSummary = "Failed"
				s.stidSummaryBlock = <STIDSummary stid={{message:"Failed"}} />
			}
			const unfilled = filledSet.filter( x => x.stidSummary == "???");
			if( unfilled.length == 0 ) {
				setStamps( filledSet );
			}
		})
	}

	const holdIDSetValue = ( idx, attr, value ) => {
		console.log( `holdIDSetValue ${idx} ${attr} ${value}`);
		while( selectedStamp.stid.identifiers.length <= idx ) {
			selectedStamp.stid.identifiers.push( { category:"", categoryNumber:"", categoryExtra:"",splitPercentage:"" } )
		}
		const idset = selectedStamp.stid.identifiers[ idx ];
		idset[ attr ] = value;
		console.log( selectedStamp );
	}

	const previewClicked = () => {
		const stid = prepPayload();
		if( !stid ) { return; }
		const payload = { identifiers: stid.identifiers };
		setStidJSON( JSON.stringify( payload, null, 4 ) );
	}

	const curlClicked = () => {
		const stid = prepPayload();
		if( !stid ) { return; }
		const cmd = `curl -s -k $AURL/$BUCKET/${stid._id} -X PUT -H $CTAJ -d '` + JSON.stringify( stid ) + "'";
		setStidJSON( cmd );
		navigator.clipboard.writeText(cmd);
		sayuser( "Command copied to clipboard");
	}

	const saveClicked = () => {
		const stid = prepPayload();
		if( !stid ) { return; }
		const payload = { identifiers: stid.identifiers };
		setStidJSON( JSON.stringify( payload, null, 4 ) );
		const url = `/api/stamps/${Session.corporate.companyId}/${selectedStamp.uniqueID}`;
		sayuser( url );
		WhitespaceAPI.post( url, stid ).then( response => {
			setStidJSON( JSON.stringify( response.data, null, 4 ) );
		});
	}

	const prepPayload = () => {
		const stid = selectedStamp.stid;
		setStidJSON( "" );
		if( !stid ) {
			sayuser( "No selectedStamp.stid");
			return null;
		}	
		stid._id = `${selectedStamp.uniqueID}::STID`;
		stid.channels = [ "shared" ];
		stid.updatedAt = utils.formatDateTime( new Date(), "YYYY-MM-DD HH:mm:ss");
		stid.createdAt = stid.createdAt || stid.updatedAt;
		stid.type = "RWStampIdentifiers";
		for( let idx = stid.identifiers.length - 1; idx >= 0; idx-- ) {
			const idset : any = stid.identifiers[ idx ];
			Object.keys(idset).forEach(key => {
				idset[ key ] = idset[ key ].trim() || null;
				if (idset[key] === null || key.startsWith("S" ) || key.startsWith("stamp" ) ) {
					delete idset[key];
				}
			});
			if( Object.keys(idset).length == 0 ) {
				const removed = stid.identifiers.splice( idx, 1 );
			}
		}		
		if( stid.identifiers.length == 0 ) {
			sayuser( "You must save at least one identifier set");
			return;
		}
		let failures : string[] = [];
		let totalperc = 0;
		stid.identifiers.forEach( idset => {
			failures.push( checkIDSet( idset, stid.identifiers.length ) );
			if( idset.splitPercentage ) {
				totalperc += parseFloat( idset.splitPercentage );
			}
		});
		if( stid.identifiers.length > 1 && totalperc != 100.0 ) {
			failures.push( `Split Percentages sum to ${totalperc}`);
		}
		failures = failures.filter( x => x );
		if( failures.length ) {
			sayuser( failures.join( ", "));
			return;
		}
		console.log( stid );
		stid.identifiers.forEach( idset => {
			console.log( idset );
		});
		return stid;
	}

	// https://github.com/whitespace-software/WhitespacePlatform/issues/1439
	const checkIDSet = ( idset, count ) => {
		const fourDigits = /^\d{4}$/;
		const threeUpper = /^[A-Z]{3}$/;
		const lirma = /^[A-Z]\d{4}$/;
		const ilu = /^\d{7}$/;

		if( !idset.category ) {
			return "Blank Category not allowed";
		}
		if( idset.category == "LloydsSyndicate" || idset.category == "LloydsBrussels" ) {
			if( !idset.categoryNumber || !idset.categoryNumber.match( fourDigits ) ) {
				return `${idset.category} Stamp Code must be 4 digits`;
			}
			if( !idset.categoryExtra || !idset.categoryExtra.match( threeUpper ) ) {
				return `${idset.StampType} Stamp Pseudonum must be 3 CAPITALS`;
			}
		}
		if( idset.category == "Consortium") {
			if( !idset.categoryNumber || !idset.categoryNumber.match( fourDigits ) ) {
				return `${idset.category} Stamp Code must be 4 digits`;
			}
			if( idset.categoryExtra) {
				return `${idset.category} should not have a pseudonym`;
			}
		}
		if( idset.category == "LIRMA" ) {
			if( !idset.categoryNumber || !idset.categoryNumber.match( lirma ) ) {
				return `${idset.category} Stamp Code must be like A1234`;
			}
			if( idset.categoryExtra) {
				return `${idset.category} should not have a pseudonym`;
			}
		}
		if( idset.category == "ILU" ) {
			if( !idset.categoryNumber || !idset.categoryNumber.match( ilu ) ) {
				return `${idset.category} Stamp Code must be 7 digits`;
			}
			if( idset.categoryExtra) {
				return `${idset.category} should not have a pseudonym`;
			}
		}
		if( count == 1 && idset.splitPercentage ) {
			return "Do not set split percentage when there is only one identifier";
		}
		if( count > 1 ) {
			const perc : number = idset.splitPercentage ? parseFloat( idset.splitPercentage ) : 0.0;
			if( isNaN(perc) || perc <= 0.0 || perc >= 100.0 ) {
				return `Incorrect split percentage ${perc}`;
			}
		}
		return "";
	}

	const OneIDSet = ( idxObject ) => {
		const [category, setCategory] = useState("");
		const [categoryNumber, setCategoryNumber] = useState("");
		const [categoryExtra, setCategoryExtra] = useState("");
		const [splitPercentage, setSplitPercentage] = useState("");

		useEffect(() => {
			const idx = parseInt(idxObject.idx);
			if( selectedStamp?.stid?.identifiers?.length > idx ) {
				const idset = selectedStamp.stid.identifiers[ idx ];
				setCategory( idset.category );
				setCategoryNumber( idset.categoryNumber );
				setCategoryExtra( idset.categoryExtra );
				setSplitPercentage( idset.splitPercentage );
			}
		},[idxObject]);

		const onSelectCategory = ( event ) => {
			setCategory( event.target.value );
			holdIDSetValue( parseInt(idxObject.idx), 'category', event.target.value)
		}
		const onChangeCategoryNumber = (event) => {
			setCategoryNumber( event.target.value );
			holdIDSetValue( parseInt(idxObject.idx), 'categoryNumber', event.target.value)
		}
		const onChangeCategoryExtra = (event) => {
			setCategoryExtra( event.target.value );
			holdIDSetValue( parseInt(idxObject.idx), 'categoryExtra', event.target.value)
		}
		const onChangeSplitPercentage = (event) => {
			setSplitPercentage( event.target.value );
			holdIDSetValue( parseInt(idxObject.idx), 'splitPercentage', event.target.value)
		}
		return (
			<div>
				<select value={category} onChange={onSelectCategory}>
					<option value=""></option>
					<option value="Consortium">Consortium</option>
					<option value="Coverholder">Coverholder</option>
					<option value="LloydsSyndicate">LloydsSyndicate</option>
					<option value="LloydsBrussels">LloydsBrussels</option>
					<option value="LBSConsortium">LBSConsortium</option>
					<option value="ILU">ILU</option>
					<option value="LIRMA">LIRMA</option>
					<option value="MGA">MGA</option>
					<option value="NonBureau">NonBureau</option>
					<option value="NonBureauMGA">NonBureauMGA"</option>
				</select>&nbsp;
				<input type="text" placeholder='Category Number' value={categoryNumber} onChange={onChangeCategoryNumber} />&nbsp;
				<input type="text" placeholder='Category Extra' value={categoryExtra} onChange={onChangeCategoryExtra} />&nbsp;
				<input type="text" placeholder='Split %' value={splitPercentage} onChange={onChangeSplitPercentage} />
			</div>
		)
	}
	
	const MyDialog = () => {
        return (
          <Modal size="lg" show={showDialog} onHide={() => setShowDialog(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{selectedStamp.uniqueID}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='DlgSubTitle'>StampType / BureauMarket / BureauMarketCode / BureauSubMarket / BusinessUnit</div>
				{selectedStamp.stampType} / {selectedStamp.bureauMarket} / {selectedStamp.bureauMarketCode} / {selectedStamp.bureauSubMarket} / {selectedStamp.businessUnit}
				<hr/>
				<div className='DlgSubTitle'>STID</div>{selectedStamp.stid ? selectedStamp.stid._id : ""}
				<OneIDSet idx="0" />
				<OneIDSet idx="1" />
				<OneIDSet idx="2" />
				<OneIDSet idx="3" />
				<hr/>
				<div>
				<Button onClick={previewClicked}>Preview</Button>&nbsp;
				<Button onClick={curlClicked}>Curl</Button>&nbsp;
				<Button onClick={saveClicked}>Save</Button>&nbsp;
				<span className='WarningText'>{sayuserMessage}</span>
				</div>
				<div className='Above12'>
					<textarea rows={10} value={stidJSON} className="FullWidth"></textarea>
				</div>
            </Modal.Body>
          </Modal>
        )
    }

	const rowClicked = stamp => {
		console.log( `Selected ${stamp.uniqueID} ${stamp.corporateSummary}` );
		setSelectedStamp( stamp );
		setStidJSON( JSON.stringify( stamp.stid, null, 4 ) );
		setShowDialog( true );
    }

	const describeSTID = ( stid ) => {
		if( stid.identifiers ) {
			const summaries : string[] = [];
			stid.identifiers.forEach( i => {
				let summary = `${i.category || "-"} / ${i.categoryNumber || "-"} / ${i.categoryExtra || "-"}`
				if( i.splitPercentage ) {
					summary += ` / ${i.splitPercentage}%`
				}
				summaries.push( summary );
			});
			if( summaries.length == 0 ) { 
				return( "No identifiers" );
			} else if( summaries.length == 1 ) {
				return( summaries[0] );
			} else {
				return( `${summaries.length} ids : ` + summaries.join( " & ") );
			}
		}
		return "Blank";
	}

	const CorpEditButton = ( { stamp }) => {
		return (
			<>
			<Button onClick={() => rowClicked(stamp)}>Edit</Button>
			</>
		)
	}

	if( mayNotMessage.length > 0 ) {
		return (
			<>
			<div>{mayNotMessage}</div>
			</>
		)
	} else {	
		return (
			<>
				<MyDialog />
				<Button onClick={fetchStamps}>Refresh</Button>
				<DataTable
                        columns={columns}
                        data={stamps}
                        defaultSortField="uniqueID"
                        defaultSortAsc={true}
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 50, 100, 500]}
                        // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        persistTableHead
						className='RiskGridSingleLine'
                    />
            </>
		)
	}
}