import dayjs from 'dayjs'

export const utils = {
    dateCompare : function( d1, d2 ) {
        if( d1 < d2 )
            return -1
        if( d1 > d2 )
            return 1
        return 0
    },
    formatDateTime : function( d, fmt = 'D MMM YYYY HH:mm' ) {
        if( !d )
            return ''
        return dayjs( d ).format( fmt )
    },
    rootIC : function( docID ) {
        return docID ? docID.split( '::')[0] : docID
    },
    truncateIC : function( docID ) {
        if( !docID )
            return docID
        return docID.length > 9 ? docID.substr(0,6) + '...' : docID 
    },
    umrFromDate : function( d ) {
        if( !d )
            return 'B0999JC' + '0000000000'
        else
            return 'B0999JC' + dayjs( d ).format( 'DDMMYYHHmm');
    },
    makePlural : function( count, single, plural? ) {
        if( count == 1 )
            return single;
        else
            return plural ? plural : single + "s";
    },
    validUMR : function( s ) {
        const pattern = /^B\d{4}[A-Z0-9]{1,12}$/i
        return pattern.test( s );
    },
    parseAmount : function( s ) {
        const pattern = /[A-Z]{3}\s+[0-9,.]+/
        if( pattern.test( s ) ) {
            return { currency : s.substr( 0, 3 ), figure : parseFloat( s.substr( 4 ).replaceAll( ",", "" ) )}
        }
        return null;
    },
    splitAmount : function ( s ) {
        const parsed = utils.parseAmount( s );
        if( parsed ) {
            return [ parsed.currency, parsed.figure.toFixed(2) ];
        } else {
            return [ s, "" ];
        }
    },
    formatAmount : function( currency, figure ) {
        return currency + " " + figure.toLocaleString("en-GB", { minimumFractionDigits: 2, maximumFractionDigits: 2 } ).replaceAll( ".00", "");
    },
    aggressiveTidy : function( str ) {
        if( !str ) {
            return "";
        }
        const parts = str.match( /[A-Z0-9]/gi );
        if( !parts ) {
            return "";
        }
        return parts.join( '' ).toLowerCase();
    },
    prepareForCSV : function( str ) {
        if( !str ) {
            return "";
        }
        return '"' + str.replaceAll( "\n", " ").replaceAll( "\r", "").replaceAll( "\t", " " ).replaceAll( '"', "'") + '"';
    }

}