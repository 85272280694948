import dayjs from "dayjs";
import { get } from "https";
import { DDDataPoint, DefinedData } from "../../helpers/DefinedData";
import { utils } from "../../helpers/utils";

export class RBInsured {
    InsuredName = "";
    AddressLine1 = "";
    AddressLine2 = "";
    AddressLine3 = "";
    City = "";
    Region = "";
    Country = "";
    State = "";
    Postcode = "";
    Telephone = "";
    AltTelephone = "";
    Fax = "";
    Email = "";
    static makeExample() : RBInsured {
        const result = new RBInsured();
        result.InsuredName = "Acme Widgets Inc";
        result.AddressLine1 = "1200 Main Street";
        result.City = "Kansas City";
        result.State = "MO";
        result.Postcode = "64133";
        return result;
    }
}

export class RBTaxPayable {
    TaxType = "";
    Percent = 0.0;
}

export class RBTaxPayableParent {
    TaxPayable = new RBTaxPayable();
}

export class RBBrokerCommission {
    Percent = 0.0;
    Amount = 0.0;
    static makeExample() : RBBrokerCommission {
        const result = new RBBrokerCommission();
        result.Percent = 15
        return result;
    }
}

export class RBQuotePremium {
    CurrencyCode = "";
    BasePremium = 0.0;
    BasePremiumForPeriod = 0.0;
    TechnicalPremium = 0.0;
    TechnicalPremiumForPeriod = 0.0;
    CommercialPremium = 0.0;
    CommercialPremiumForPeriod = 0.0;

    BrokerCommission = new RBBrokerCommission();
    BrokerCommissionPayable = new RBBrokerCommission();
    TaxesPayable: RBTaxPayableParent[] = [];

    // TaxesAnnualised
    // TaxesPayableReturn
    PayableAmount = 0.0;

    static makeExample() : RBQuotePremium {
        const result = new RBQuotePremium();
        result.CurrencyCode = "USD";
        result.BrokerCommission = RBBrokerCommission.makeExample();
        return result;
    }    
}

export class RBAddress {
    AddressLine1 = "";
    AddressLine2 = "";
    AddressLine3 = "";
    City = "";
    Country = "";
    State = "";
    Zipcode = "";
    static make( line1, city, state, zipcode ) : RBAddress {
        const result = new RBAddress();
        result.AddressLine1 = line1;
        result.City = city;
        result.State = state;
        result.Zipcode = zipcode;
        return result;
    }
}

export class RBLocation {
    LocationReference = "";
    PropertyType = "";
    PDValue = 0.0;
    BIValue = 0.0;
    Address = new RBAddress(); 
    // LossHistories
    static makeExample( pd, bi, line1, city, state, zipcode) : RBLocation {
        const result = new RBLocation(  );
        result.PDValue = pd;
        result.BIValue = bi;
        result.Address = RBAddress.make( line1, city, state, zipcode );
        return result;
    }
}

export class RBCarrierOption {
    OptionReference = "";    
    CoverageName = "";
}

export class RBCarrierOptionParent {
    CarrierOption = new RBCarrierOption();    
}

export class RBWarAndTerror {
    Excess = 0.0;
    Deductible = 0.0;
    BIDeductibleDays = 0;
    LimitType = "";
    Locations: RBLocation[] = [];
    WarAndTerrorPremium = new RBQuotePremium();
    CarrierOptions: RBCarrierOptionParent[] = [];
    static makeExample() : RBWarAndTerror {
        const result = new RBWarAndTerror();
        result.Excess = 100000;
        result.Deductible = 100000;
        result.BIDeductibleDays = 15;
        result.LimitType = "Aggregate";
        result.Locations.push( RBLocation.makeExample(350000, 150000, "100 First Avenue","Kansas City","MO","64133") );
        result.Locations.push( RBLocation.makeExample(200000, 120000, "200 Second Avenue","Kansas City","MO","64133") );
        return result;
    }
}

export class RBRiskDetails {
    BrokerName = "";
    BrokerRef = "";
    Industry = "";
    LloydsRiskCode = "";
    Excess = 0.0;
    Deductible = 0.0;
    BIDeductibleDays = 0;
    LimitType = "";
    CarrierRef = "";
    PolicyDuration = 0;
    PDValue = 0.0;
    BIValue = 0.0;
    static makeExample() : RBRiskDetails {
        const result = new RBRiskDetails();
        result.BrokerName = "State Wide Brokers of Missouri Inc";
        result.BrokerRef = "SWB090807";
        result.Industry = "Storage";
        return result;
    }
}



export class RBProductDetails {
    WarAndTerror = new RBWarAndTerror(); 
    RiskDetails = new RBRiskDetails();   
    static makeExample() : RBProductDetails {
        const result = new RBProductDetails();
        result.WarAndTerror = RBWarAndTerror.makeExample();
        result.RiskDetails = RBRiskDetails.makeExample();
        return result;
    }
}

export class RBRisk {
    SchemeKeys = new RBSchemeKeys();
    Insured = new RBInsured();
    Inception = "";
    Expiry = "";
    QuotePremium = new RBQuotePremium();
    ProductDetails = new RBProductDetails();

    static makeExample() : RBRisk {
        const result = new RBRisk();
        result.SchemeKeys = RBSchemeKeys.makeExample();
        result.Insured = RBInsured.makeExample();
        result.Inception = "2022-03-01T00:00:00";
        result.Expiry = "2023-02-28T23:59:59";
        result.QuotePremium = RBQuotePremium.makeExample();
        result.ProductDetails = RBProductDetails.makeExample();
        return result;
    }
}

export class RBSchemeKeys {
    ProductName = "";
    EffectiveDateTime = "";
    static makeExample() : RBSchemeKeys {
        const result = new RBSchemeKeys();
        result.ProductName = "Carrier 1 Product";
        result.EffectiveDateTime = dayjs( new Date() ).format( "YYYY-MM-DDTHH:mm:00");
        return result;
    }
}

export class RBResponse {
    RecordEntryGuid = "";
    Key = ""; 
    SchemeKeys = new RBSchemeKeys();  
    Insured = new RBInsured(); 
    // LibraryEndorsements
    HubStatusCode = "";
    RatingOutcome = "";
    DataCaptureCompleted = false;
    // DeclineReasons
    // ReferReasons
    // InformationMessages
    // RatedEndorsements
    QuotePremium = new RBQuotePremium();
}

export class RBFiller {
    ddpoints : DDDataPoint[];
    constructor( ddpoints : DDDataPoint[] ) {
        this.ddpoints = [...ddpoints];
    }
    make() : RBRisk {
        const result = new RBRisk();
        const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:00";

        result.SchemeKeys.ProductName = "Carrier 1 Product";
        result.SchemeKeys.EffectiveDateTime = dayjs( new Date() ).format( "YYYY-MM-DDTHH:mm:00");

        result.Insured.InsuredName = this.getValue( "Insured", "Insured_Name" );
        result.Insured.AddressLine1 = this.getValue( "PrincipalAddress", "Number_And_Street" );
        result.Insured.City = this.getValue( "PrincipalAddress", "City" );
        result.Insured.Postcode = this.getValue( "PrincipalAddress", "Postal_Code" );

        result.Inception = utils.formatDateTime( this.getValue( "Period", "Inception_Date" ), DATE_TIME_FORMAT );
        result.Expiry = utils.formatDateTime( this.getValue( "Period", "Expiry_Date" ), DATE_TIME_FORMAT );

        result.ProductDetails.RiskDetails.Industry = this.getValue( "Interest", "Industry_Classification");
        
        const locations = {};
        DefinedData.findAll( this.ddpoints, {mrcHeading: "Interest", tag : ""}).map( ( p ) => {
            if( p.tag == "Industry_Classification" ) {
                return;
            }
            if( !locations[ p.parentID ] ) {
                locations[ p.parentID ] = new RBLocation();
            }
            if( p.tag == "Number_And_Street" )  locations[ p.parentID ].Address.AddressLine1 = p.value;
            if( p.tag == "City" )               locations[ p.parentID ].Address.City = p.value;
            if( p.tag == "Postal_Code" )        locations[ p.parentID ].Address.Zipcode = p.value;
            if( p.tag == "Coverage_Amount" ) {
                locations[ p.parentID ].BIValue = utils.parseAmount( p.value )?.figure;
                locations[ p.parentID ].PDValue = locations[ p.parentID ].BIValue * 0.8;
            }
        });
        Object.keys(locations).forEach( (key) => {
            result.ProductDetails.WarAndTerror.Locations.push( locations[ key ] );
        });
        console.log( "RBFiller.make returns...");
        console.log( result );
        return result;
    }

    getPageIDs( mrcHeading, tag ) : string[] { 
        const result: string[] = [];
        DefinedData.findAll( this.ddpoints, {mrcHeading: mrcHeading, tag : tag}).map( ( p ) => { if( !result.includes( p.parentID ) ) { result.push( p.parentID ); } });
        return result;
    }

    getValue( mrcHeading, tag ) : string {
        return DefinedData.findFirst( this.ddpoints, {mrcHeading: mrcHeading, tag : tag})?.value || "";
    }

}