import { useEffect, useState } from "react"
import { Dropdown, DropdownButton } from "react-bootstrap"
import { NotificationManager } from 'react-notifications'
import { utils } from "../../helpers/utils"
import { Query, whitespace } from "../../helpers/whitespace"
import './Decorator.css'

const rulebook_example = {
    SchemeKeys: {
      ProductName: "Carrier 1 Product",
      EffectiveDateTime: "2021-08-12T08:04:08.674Z",
      Keys: {}
    },
    Insured: {
      InsuredName: "Whitespace Software Limited",
      AddressLine1: "Ikon Studios",
      AddressLine2: "30/31 Cowcross Street",
      City: "London",
      Postcode: "EC1M 6DQ",
      Telephone: "020 7240 0208",
      Email: "jonathan.clarke@whitespace.co.uk"
    },
    Inception: "2022-01-01T00:00:00.000Z",
    Expiry: "2022-12-31T23:59:59.999Z",
    QuotePremium: {
      CurrencyCode: "GBP",
      BrokerCommission: {
        Percent: 15
      },
      TaxesPayable: [
        {
          TaxPayable: {
            TaxType: "string",
            Percent: 0
          }
        }
      ]
    },
    ProductDetails: {
      RiskDetails: {
        BrokerName: "McGill and Partners",
        BrokerRef: "B1740JC1412211548",
        Industry: "Software Development",
        LloydsRiskCode: "LloydsRiskCode",
        Excess: 50000,
        Deductible: 25000,
        BIDeductibleDays: 7
      },
      WarAndTerror: {
        Excess: 250000,
        Deductible: 100000,
        BIDeductibleDays: 15,
        LimitType: "Aggregate",
        Locations: [
          {
            Location: {
              PropertyType: "Office",
              PDValue: 350000,
              BIValue: 150000,
              Address: {
                AddressLine1: "30/31 Cowcross Street",
                City: "London",
                Country: "UK",
                Zipcode: "EC1M 6DQ"
              },
              LossHistories: []
            }
          },
          {
            Location: {
              PropertyType: "Office",
              PDValue: 150000,
              BIValue: 250000,
              Address: {
                AddressLine1: "22 Bishopsgate",
                City: "London",
                Zipcode: "EC2N 4AJ"
              },
              LossHistories: []
            }
          }
        ]
      }
    }
  }


export const Decorator = () => {
    const [example, setExample] = useState( JSON.stringify( rulebook_example, null, 4 ) )
    const [template, setTemplate] = useState( { id : "ICxxxx", name : ""})
    const [LIIndexes, setLIIndexes] = useState( "3,4,5,6,7")
    const [fetched, setFetched] = useState( [ { id: "ICxxx", name : ""} ] );   // , { id: "ICyyy", name : "Second"}])

    useEffect(() => {
        const payload = { statuses : [ "Template"] }
        whitespace(Query.POST, '/api/summary', payload ).then(response => {
            if( response.data && response.data.length ) {
                const myArray : any[] = [];
                response.data.forEach( item => {
                    const doc = item.docs.filter( (doc) => (doc.type === "RWPlacing") )[0];
                    const name = doc?.insuredName || item.rootID;
                    const f : any = { id : item.rootID, name: name };
                    myArray.push( f );
                });
                setFetched( myArray );
                if( myArray.length > 0 ) {
                    setTemplate( myArray[0]);
                }
            }
        })
    }, [])

    const changeExample = ( event ) => {
        setExample( event.target.value )
    }
    const changeLIIndexes = ( event ) => {
        setLIIndexes( event.target.value )
    }

    const pickTemplate = ( key ) => {
        const pos = key.indexOf( " ");
        setTemplate( { id : key.substr(0,pos), name : key.substr( pos + 1 )});
    }

    const getLIIndex = ( i ) => {
        const parts = LIIndexes.split( ",");
        const badResult = -1;
        if( i < 0 || i >= parts.length )
            return badResult;
        const result = parseInt( parts[i]);
        if( result <= 0 )
            return badResult;
        return result;
    }

    const runProcess = () => {
        const url = `/api/risks/${template.id}/cloneDraft`;
        const input = JSON.parse( example );
        const umr = utils.umrFromDate( new Date() );
        const payload = { umr: umr, placementType: "FullContract", insuredName : input.Insured.InsuredName }
        whitespace( Query.POST, url, payload ).then(response => {
            if( response?.data ) {
                const riskID =  response.data.id;
                NotificationManager.success('Created ' + riskID );
                fillInsured( riskID, input );
            }
        });
    }

    const fillHardCoded = () => {
        const riskID = "IC7E63AABD-17C6-48B6-B3FE-6F40337B13F3";
        const input = JSON.parse( example );
        fillInsured( riskID, input );
    }

    const fillInsured = ( riskID, input ) => {
        whitespace( Query.GET, `/api/risks/${riskID}`).then( response => {
            if( response.data ) {
                const rev = response.data._rev;
                const url = `/api/risks/${riskID}/editLineItem`;
                const element = { index: 0, text : input.Insured.InsuredName };
                const payload = { op: "edit", _rev : rev, index : getLIIndex(0), elements : [ element ] };
                whitespace( Query.POST, url, payload ).then(response => {
                    if( response?.data ) {
                        fillAddress( riskID, input );
                    }
                });
            }
        });
    }

    const fillAddress = ( riskID, input ) => {
        whitespace( Query.GET, `/api/risks/${riskID}`).then( response => {
            if( response.data ) {
                const rev = response.data._rev;
                const url = `/api/risks/${riskID}/editLineItem`;
                const textArray = [ input.Insured.AddressLine1, input.Insured.AddressLine2, 
                    input.Insured.AddressLine3, input.Insured.City, input.Insured.Postcode ]
                const element = { index: 0, textArray : textArray.filter( (x) => (x) ) };
                const payload = { op: "edit", _rev : rev, index : getLIIndex(1), elements : [ element ] };
                whitespace( Query.POST, url, payload ).then(response => {
                    if( response?.data ) {
                        fillPeriod( riskID, input );
                    }
                });
            }
        });
    }

    const fillPeriod = ( riskID, input ) => {
        whitespace( Query.GET, `/api/risks/${riskID}`).then( response => {
            if( response.data ) {
                const rev = response.data._rev;
                const url = `/api/risks/${riskID}/editLineItem`;
                const inception = utils.formatDateTime( input.Inception );
                const expiry = utils.formatDateTime( input.Expiry );
                const element = { index: 0, text : `From ${inception} to ${expiry}` };
                const payload = { op: "edit", _rev : rev, index : getLIIndex(2), elements : [ element ] };
                whitespace( Query.POST, url, payload ).then(response => {
                    if( response?.data ) {
                        fillInterest( riskID, input );
                    }
                });
            }
        });
    }

    const fillInterest = ( riskID, input ) => {
        whitespace( Query.GET, `/api/risks/${riskID}`).then( response => {
            if( response.data ) {
                const rev = response.data._rev;
                const url = `/api/risks/${riskID}/editLineItem`;
                const inception = utils.formatDateTime( input.Inception );
                const expiry = utils.formatDateTime( input.Expiry );
                const elements: any[]= [];
                const wat = input.ProductDetails.WarAndTerror;
                const curr = input.QuotePremium.CurrencyCode;
                let index = 0;
                for( let i = 0, len = wat.Locations.length; i < len; i++ ) {
                    const location = wat.Locations[i].Location;
                    const text = makeLocationAddress( location );
                    elements.push( { index : index++, text : text, html : `<b><u>${text}</u></b>` } );
                    elements.push( { index: index++, text: `Property Type: ${location.PropertyType}`});
                    elements.push( { index: index++, text: `PD Value: ${curr} ${location.PDValue}`});
                    elements.push( { index: index++, text: `BI Value: ${curr} ${location.BIValue}`});
                    if( location.LossHistories && location.LossHistories.length > 0 ) {
                        const losses = utils.makePlural( location.LossHistories.length, "loss", "losses"); 
                        elements.push( { index: index++, text: `${location.LossHistories.length} ${losses} reported` } );
                    } else {
                        elements.push( { index: index++, text: "No loss history" } );
                    }
                }
                const payload = { op: "edit", _rev : rev, index : getLIIndex(3), elements : elements };
                whitespace( Query.POST, url, payload ).then(response => {
                    if( response?.data ) {
                        fillLimitOfLiability( riskID, input );
                    }
                });
            }
        });
    }

    const makeLocationAddress = ( location ) => {
        const address = location.Address;
        if( !address ) {
            return "location.Address not present";
        }
        const parts = [ address.AddressLine1, address.AddressLine2, address.AddressLine3, address.City, address.Zipcode, address.Country ];
        const result = parts.filter( (x) => (x) ).join( ", ");
        return result;
    }

    const fillLimitOfLiability = ( riskID, input ) => {
        whitespace( Query.GET, `/api/risks/${riskID}`).then( response => {
            if( response.data ) {
                const rev = response.data._rev;
                const url = `/api/risks/${riskID}/editLineItem`;
                const inception = utils.formatDateTime( input.Inception );
                const expiry = utils.formatDateTime( input.Expiry );
                const elements: any[]= [];
                const wat = input.ProductDetails.WarAndTerror;
                const curr = input.QuotePremium.CurrencyCode;
                let index = 0;
                // let element =  };
                elements.push( { index : index++, text : `Excess ${curr} ${wat.Excess}`} );
                elements.push( { index : index++, text : `Deductible ${curr} ${wat.Deductible}, BI Deductible Days ${wat.BIDeductibleDays}` } );
                elements.push( { index: index++, text: `Limit Type: ${wat.LimitType}`} );
                // for( i = 0, len = input.ProductDetails.)
                const payload = { op: "edit", _rev : rev, index : getLIIndex(4), elements : elements };
                whitespace( Query.POST, url, payload ).then(response => {
                    if( response?.data ) {
                        NotificationManager.success( `Decorated ${riskID}`);
                    }
                });
            }
        });
    }

    return (
        <>

        <div>
            <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
            {fetched.length} fetched {utils.makePlural(fetched.length,"template")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    fetched.map( tp => {
                        return <Dropdown.Item onSelect={pickTemplate} eventKey={tp.id + " " + tp.name}>{tp.id} {tp.name}</Dropdown.Item>
                    })
                }
            </Dropdown.Menu>
            </Dropdown>
        </div>
        <div>
            Template : &nbsp;{template.id} : {template.name}
        </div>
        <div>
            Line Item Indexes : 
            &nbsp;<input type="Text" value={LIIndexes} onChange={changeLIIndexes} />
            &nbsp;: <span className="MyLink" onClick={runProcess}>Run</span>
        </div>
        <textarea className='PayloadTA' value={example} onChange={changeExample} />
        </>
    )
}