import { getBaseUrl, getSessionToken, logout } from './auth'
import axios, { AxiosRequestConfig } from 'axios'
import { NotificationManager } from 'react-notifications'
import { config } from 'dotenv/types';
import { Query } from './whitespace';
// import dotenv from 'dotenv';
// dotenv.config();

export enum Method { GET, BYTEARRAY, POST }

export const RBSettings = {
    root : "",
    user : "",
    password : "",
    token : "",
    product : ""
}

export class RulebookAPI {
    static initSettings( root : string | undefined, user : string | undefined, password : string | undefined ) {
        RBSettings.root = root || "";
        RBSettings.user = user || "";
        RBSettings.password = password || "";
        RBSettings.token = "";
    }

    static connect() : any {
        const url = `${RBSettings.root}/token`;
        const payload = `grant_type=password&username=${RBSettings.user}&password=${RBSettings.password}`;
        console.log( "calling", url, payload );
        const config : AxiosRequestConfig = {};

        return axios
        .post(url, payload, config )
        .then(function (response) { // Success
            console.log( url, response );
            return response.data;
        })
        .catch(function (error) { // Failure
            RulebookAPI.handleError(error, url)
        })
    }

    static get( endpoint: string ) : any {
        return RulebookAPI.doCall( Query.GET, endpoint );
    }
    static post( endpoint: string, payload : any ) : any {
        return RulebookAPI.doCall( Query.POST, endpoint, payload );
    }

    static doCall( type : Query, endpoint: string, payload: any = null ) : any {
        const base = getBaseUrl();
        const url = `${RBSettings.root}${endpoint}`;
        console.log( "calling", url );

        const config : AxiosRequestConfig = { 
            headers: { 
                'Authorization': `Bearer ${RBSettings.token}`,
                "Content-type" : "Application/json"
            },
            withCredentials : false
        }
        console.log( config );
        if( type == Query.GET ) {
            return axios
            .get(url, config )
            .then(function (response) { // Success
                console.log( response );
                return response
            })
            .catch(function (error) { // Failure
                RulebookAPI.handleError(error, url)
            })
        } else if(type === Query.POST) {
            return axios
            .post(url, payload, config)
            .then(function (response) { // Success
                return response
            })
            .catch(function (error) { // Failure
                RulebookAPI.handleError(error, url)
            })
        } 
    }

    static handleError( error, url ) : void {
        console.error(error, url)
        NotificationManager.error(`Error calling ${url}`)
    }
}



/*
export const rulebook = (type: Method, endpoint: string, payload: any = null ): any => {
    const base = process.env.REACT_APP_RULEBOOK_ROOT;
    const url = base + endpoint

    const handleError = ( error, url ) => {
        console.error(error, url)
        NotificationManager.error(`Error calling ${url}`)

        if(error.response.status === 401) {
            logout()
        }
    }

    const config : AxiosRequestConfig = { 
        headers: { 
            'Authorization': `Bearer ${getSessionToken()}`
        } 
    }

    console.log( `calling ${url}`, config.headers.UserID || 'No UserID' )
    if(type === Method.POST) {
        return axios
        .post(url, payload, config)
        .then(function (response) { // Success
            return response
        })
        .catch(function (error) { // Failure
            handleError(error, url)
        })
    } else if(type === Method.GET) {
        return axios
        .get(url, config)
        .then(function (response) { // Success
            return response
        })
        .catch(function (error) { // Failure
            handleError(error, url)
        })
    }
}
*/

