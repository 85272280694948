import { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"

export const TabbedViewer = ( {inputs} ) => {
    const [values, setValues] = useState<any[]>( [] )

    useEffect(() => {
        setValues( inputs )
    }, [ inputs ])

    const renderOneTab = (value, i) => {
        if( value.url ) {
            return (
                <Tab key={i+1} eventKey={i+1} title={i+1}>
                    <div className="TVPane">
                        <div className="TVTitle">{value.url}</div>
                        <pre className="TVPre">{value.response}</pre>
                    </div>
                </Tab>
            )    
        } 
        return (
            <Tab key={i+1} eventKey={i+1} title={i+1}>
                <div className="TVPane"><pre className="TVPre">{value}</pre></div>
            </Tab>
        )
    }

    return (
        <>
        <Tabs className="TVTabs">
            {values.map( (value,i) => { return renderOneTab( value, i ); } ) }
        </Tabs>
        </>
    )
}