import { getBaseUrl, getSessionToken, logout } from './auth'
import axios, { AxiosRequestConfig } from 'axios'
import { NotificationManager } from 'react-notifications'

export enum Query { GET, BYTEARRAY, POST, BLOB }
import { MUID } from '../App'

export class WhitespaceAPI {
    static get( endpoint: string, options : any = {} ) : any {
        return WhitespaceAPI.doCall( Query.GET, endpoint, null, options );
    }
    static post( endpoint: string, payload : any, options : any = {} ) : any {
        return WhitespaceAPI.doCall( Query.POST, endpoint, payload, options );
    }
    
    static doCall( type : Query, endpoint: string, payload: any = null, options: any = {} ) : any {
        const base = getBaseUrl();
        const url = base + endpoint;        
        console.log( "calling", url );

        const config : AxiosRequestConfig = { 
            headers: { 
                'Authorization': `Bearer ${getSessionToken()}`
            } 
        }
        const addingUserIdBreaksMiVAPIbecauseCORS = true
        if( addingUserIdBreaksMiVAPIbecauseCORS && !endpoint.startsWith( '/auth' ) && MUID ) {
            config.headers.UserID = MUID
        }
        if( type == Query.GET ) {
            return axios
            .get(url, config )
            .then(function (response) { // Success
                console.log( response );
                return response
            })
            .catch(function (error) { // Failure
                WhitespaceAPI.handleError(error, url, options )
            })
        } else if(type === Query.POST) {
            return axios
            .post(url, payload, config)
            .then(function (response) { // Success
                return response
            })
            .catch(function (error) { // Failure
                WhitespaceAPI.handleError(error, url, options )
            })
        } else if(type === Query.BYTEARRAY) {
            config.responseType = 'arraybuffer'
            return axios
            .get(url, config)
            .then(function (response) { // Success
                return response
            })
            .catch(function (error) { // Failure
                console.error(error, url)
            })
        }
    }

    static handleError( error, url, options ) : void {
        if( options.silent != true ) {
            console.error(error, url);
            NotificationManager.error(`Error calling ${url}`);
        }
    }
}

export const whitespace = (type: Query, endpoint: string, payload: any = null, options = {} ): any => {
    const base = getBaseUrl()
    const url = base + endpoint

    const handleError = ( error, url, options ) => {
        console.error(error, url)
        if( options.silent != true ) {
            NotificationManager.error(`Error calling ${url}`)
        }
        if(error.response.status === 401) {
            logout()
        }
    }

    const config : AxiosRequestConfig = { 
        headers: { 
            'Authorization': `Bearer ${getSessionToken()}`
        } 
    }

    const addingUserIdBreaksMiVAPIbecauseCORS = true
    if( addingUserIdBreaksMiVAPIbecauseCORS && !endpoint.startsWith( '/auth' ) && MUID ) {
        config.headers.UserID = MUID
    }

    console.log( `calling ${url}`, config.headers.UserID || 'No UserID' )
    if(type === Query.POST) {
        return axios
        .post(url, payload, config)
        .then(function (response) { // Success
            return response
        })
        .catch(function (error) { // Failure
            handleError(error, url, options )
        })
    } else if(type === Query.GET) {
        return axios
        .get(url, config)
        .then(function (response) { // Success
            return response
        })
        .catch(function (error) { // Failure
            handleError(error, url, options )
        })
    } else if(type === Query.BYTEARRAY) {
        config.responseType = 'arraybuffer'
        return axios
        .get(url, config)
        .then(function (response) { // Success
            return response
        })
        .catch(function (error) { // Failure
            console.error(error, url)
        })

    }
    else if(type === Query.BLOB) {
        config.responseType = 'blob'
        return axios
        .post(url, payload, config)
        .then(function (response) { // Success
            return response
        })
        .catch(function (error) { // Failure
            console.error(error, url)
        })

    }
}

export const localresource = (type: Query, endpoint: string, payload: any = null, options = {} ): any => {
    const base = "";
    const url = base + endpoint

    const handleError = ( error, url, options ) => {
        console.error(error, url)
        if( options.silent != true ) {
            NotificationManager.error(`Error calling ${url}`)
        }
        if(error.response.status === 401) {
            logout()
        }
    }

    const config : AxiosRequestConfig = { 
        headers: {} 
    }

    console.log( `calling ${url}` );
    if(type === Query.BLOB) {
        config.responseType = 'blob'
        return axios
        .get(url, config)
        .then(function (response) { // Success
            return response
        })
        .catch(function (error) { // Failure
            console.error(error, url)
        })

    }
} 

