/**
 * Specific to the client (underwriters)

We will likely want to show most of these metrics as monthly progressions dating from the time the client joined the platform.

1. Quote to bind ratio
- ::UQ1's acceptance rate, the success ratio

2. Average transaction duration (completed quote to bind)
- 

3. Monthly volume of business transacted, in premium $ (completed quote to bind)

4. Monthly number of risks written (as lead)

5. Monthly volume of business written as lead, in premium $

6. Monthly number of risks written (following lines)

7. Monthly volume of business written as follower, in premium $

8. Pie chart of premium written on platform by LOB

9. Bar chart of premium sourced by broker

10. Breakdown of premium sourced by broker by LOB

11. Quote to bind ratios by broker

12. Geographic sources of business written, in premium $

13. Number of endorsements processed through platform

Benchmarking against the population of platform users

1. Quote to bind ratio relative to all platform users (by percentile)

2. Average transaction duration (completed quote to bind) relative to all platform users (by percentile)

3. Monthly volume of business transacted, in premium $ (completed quote to bind) relative to all platform users (by percentile)
*/

import { QuoteToBindRatio } from './QuoteToBindRatio'
import { AverageTransactionDuration } from './AverageTransactionDuration'
import { MonthlyVolumeOfBusiness } from './MonthlyVolumeOfBusiness'

export const infographics = () => {
    return [
        {}, // Menu
        {
            order: 1,
            id: 'quotetobindratio',
            beta: false,
            component: QuoteToBindRatio,
            title: 'Quote to Bind Ratio',
            type: 'bar'
        }, 
        {
            order: 2,
            id: 'averagetransactionduration',
            beta: false,
            component: AverageTransactionDuration,
            title: 'Average Transaction Duration',
            type: 'bar'
        },
        {
            order: 3,
            id: 'monthlyvolumeofbusiness',
            beta: false,
            component: MonthlyVolumeOfBusiness,
            title: 'Monthly Volume of Business',
            type: 'bar'
        },
    ]
}