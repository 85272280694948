import dotenv from 'dotenv'
import React, { useState, useEffect, ReactElement } from 'react'
import { Form, Button } from 'react-bootstrap'
import { ENVIRONMENTS } from '../constants/environments'
import { isUserPermitted, getMsal } from '../helpers/auth'
import { NotificationManager } from 'react-notifications'
import logo from '../assets/images/ws.svg'
import { CLIENT } from '../constants/client'
import './Login.css'
dotenv.config()

export const Login = ({ handler } ) : ReactElement => {
    const [environment, setEnvironment] = useState<string>()

    useEffect(() => {
        const enabledEnvironments = ENVIRONMENTS.filter(environment => {
            return process.env.REACT_APP_ENABLED_ENVIRONMENTS?.split(' ').includes(environment.name)
        })
        if (enabledEnvironments.length === 1) {
            setEnvironment(enabledEnvironments[0].name)
        }
    }, [])

    const login = (e, environmentName) => {
        e.preventDefault()
        localStorage.setItem('cluster', environmentName)
        if(typeof(environmentName) !== 'undefined') {
          const environment = ENVIRONMENTS.filter(environment => {return environment.name === environmentName}).pop()
          const msal = getMsal(environment)
          const loginRequest = {
            scopes: ['openid', 'email', 'profile']
          }
          msal.loginPopup(loginRequest).then((loginResponse) => {
              console.log( 'loginResponse', loginResponse )
            if(isUserPermitted(loginResponse.account.idToken.email)) {
                localStorage.setItem('session', JSON.stringify(loginResponse))
                handler(true)
            } else { 
                NotificationManager.error('Sorry, this user does not match any configured users or domains for this app')
                handler(false)
                localStorage.clear()
            }
          }).catch(function (error) {
            console.warn(error)
          })
        }
    }

    const environmentSelector = () => {
        const enabledEnvironments = ENVIRONMENTS.filter(environment => {
            return process.env.REACT_APP_ENABLED_ENVIRONMENTS?.split(' ').includes(environment.name)
        })
        if (enabledEnvironments.length === 1) {
            return ('')
        } else {
            return (
                <Form.Group>
                    <Form.Control as="select" onChange={(event) => setEnvironment(event.target.value)}>
                    <option>Choose an Environment</option>
                    {enabledEnvironments.map(environment => {
                        return (<option key={`key-${environment.name}`}>{ environment.name }</option>)
                    })}
                    </Form.Control>
                </Form.Group>
            )
        }
    }

    const clearAll = () => {
        localStorage.clear()
        NotificationManager.success( 'Previous login data cleared')
    }

    return (
        <div className="Login-form">
            <img src={logo} className="Ws-logo" alt="Whitespace" />
            <Form onSubmit={(e) => login(e, environment)}>
            <Form.Group>
                {environmentSelector()}
                <Button type="submit">
                    Sign In
                </Button>
                &nbsp;
                <Button onClick={() => clearAll()} >
                    Clear
                </Button>
            </Form.Group>
            </Form>
            <div className='VersionMessage'>Last Update 9/12/2022 build: {CLIENT.name} / {process.env.REACT_APP_CLIENT_NAME}</div>
        </div>
    )
}
